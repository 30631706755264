.applicant {
  margin-left: 330px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 30px;
  .wrapper {
    button {
      min-width: 180px;
    }
  }
  .info {
    button {
      min-width: 400px;
    }
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      color: #c1c1c1;
    }
  }
  .data {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 20px;
      color: #c1c1c1;
    }
    .icons {
      display: flex;
      margin-bottom: 20px;
      svg {
        margin-right: 5px;
        width: 16px;
        height: auto;
      }
    }
    .buttons {
      margin-bottom: 10px;
      button {
        margin: 0;
        margin-bottom: 10px;
      }
    }
    .comment {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        padding-left: 10px;
        color: #c1c1c1;
        font-size: 16px;
      }
    }
    .url {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        padding-left: 10px;
        color: #c1c1c1;
        font-size: 16px;
      }
    }
  }
}
