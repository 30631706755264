@import "../../../../assets/scss/_media-queries.scss";

table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  @include custom-max(1000) {
    .tableContainer{
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      tr {
        display: block!important;
        margin-bottom: 2rem;
      }
      td {
        border-bottom: 1px solid #dddddd36;
        padding: 14px 60px;
        text-align: right;
      }
      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      td:last-child {
        border-bottom: 0;
      }
    }
  }
  