.sidebar {
    padding: 10px 5px;
    background-color: #1f1f1f;
    color: white;
    width: 343px;
    z-index: 200;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    top: 45px;
    transition: right 1s ease-out;
    margin-top: 25px;
    border: 1px solid #484848;

    &.close {
        right: -350px;
        transition: right 1s ease-out;
    }
    .closeComments {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #484848;
        padding: 0px 20px;
        align-items: center;
        padding-bottom: 15px;
        padding-top: 5px;

        p {
            color: white;
        }

        button {
            background-color: #aaaaaa;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .firstChat {
        display: flex;
        padding: 10px 10px 10px 7px;
        gap: 15px;
        align-items: flex-start;
        .imgBig{
            height: 40px;
            width: 40px;
        }
        img {
            height: 32px;
            width: 32px;
            border-radius: 50%;
        }
        .nameDescription {
            width: 85%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                text-align: left;
                padding-left: 0px;
                font-size: 14px;
                color: #B8B8B8;
            }
            .infoIcons {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-bottom: 15px;    
                width: 100%;
                justify-content: space-between;
                .nameDate{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .icons{
                        background-color: #262626;
                        border-radius: 10px;
                        padding: 5px 8px;
                    }
                }
                .date, span {
                    color: #707070;
                    font-size: 12px;
                }

                p {
                    color: #E8E8E8;
                    padding-bottom: 0px;
                }
            }

            .desc {
                padding-bottom: 10px;
                color: #B8B8B8;
            }
        }
        .imgStatus{
            display: flex;
            align-items: flex-end;
            .circle{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: 2.5px solid #151515;
                margin-left:25px;
                margin-bottom: -2px;
                position: absolute; 
                &.on{
                    background-color: #058065;
                }
                &.off{
                    background-color:  #8A9193;
                } 
            }
        }
        &.bigChat{
            padding: 20px 10px;
        }
    }

    .imgs {
        display: flex;
        padding: 0px 15px;
        align-items: center;
        padding-left: 60px;
        img,
        .jd {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid #262626;
        }

        .jd {
            padding-left: 0px;
            background-color: #0693da;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -7px;

            p {
                font-size: 14px;
            }
        }
        svg{
            width: 14px;
            height: 20px;
            path{
                fill: #888B8C;
            }
        }
        .commentsPart{
            color: #B8B8B8;
            font-size: 12px;
            padding: 0px 10px;
        }
        .date{
            color: #707070;
            font-size: 12px;
        }
    }
    .imgs img:nth-of-type(2){
        margin-left: -10px;
    }
    .load{
        border-radius: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .load1{
        background: #151515;
        border: 1px solid #2B2C2C;
        padding: 4px;
        width: 170px;
        margin: 0 auto;
        position: relative;
        margin-top: 60px;
        margin-bottom: 15px;
    }
    .load2{
        background: #151515;
        border: 1px solid #2B2C2C;
        padding: 4px;
        width: 190px;
        position: absolute;
        border-radius: 10px;
    }
    .load3{
        background: #1C1C1C;
        border: 1px solid #2B2C2C;
        padding: 10px;
        width: 200px;
        position: absolute;
        font-size: 14px;
        color: #B8B8B8;
    }
    .commentsRight{
        padding-left: 55px;

    }
    .writeComment{
        padding: 15px 15px 5px 15px;
        border-top: 1px solid #484848;
        .icons{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 7px 8px;
            border: 1px solid #bdb6b3;
            border-radius: 5px;
            svg{
                width: 14px;
                height: 14px;
            }
            input[type=text]{
                width: 100%;
                background-color: transparent;
                border: none;
                padding-left: 10px;
                color: #B8B8B8;
            }
        }
    }
}
