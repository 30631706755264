.personalInfo {
    width: 97%;
    margin-top: 15px;
    border-radius: 5px;
    padding-left: 12px;
    padding-top: 10px;
    background: #1A1A1A;
    border: 1px solid #383838;
    color: #ffffff71;
    font-size: 14px;
    &:focus {
        border-bottom: 1px solid var(--orange);
      }
    &::placeholder {
        color: var(--light-gray-v3);
    }
}