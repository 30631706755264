.container {
    max-width: 1200px;
    margin: 0 auto;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #D4D9DB;
            margin: 32px 0;
        }

        button {
            padding: 0 1rem;

            &:hover {
                cursor: pointer;
            }
        }
    }
}


@media(max-width: 1299px){
    .container{
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  
  .bottom{
    margin-top: -6rem;
}