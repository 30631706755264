@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/colors";

.sharedModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg,
      rgba(51, 51, 51, 0.38) 10.97%,
      rgba(16, 16, 16, 0.38) 110.97%);
  backdrop-filter: blur(25px);
  @include flex-align(center, center);

  .modal {
    max-width: 40rem;
    width: 100%;
    border-radius: 10px;
    background-color: var(--dark-gray);

    .heading {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color: var(--dark-gray-v1);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      svg {
        transform: rotate(-45deg) scale(1.5);
        cursor: pointer;
      }

      h3 {
        color: rgba(255, 255, 255, 0.74);
        font-weight: 550;
      }
    }

    .content {
      padding: 2rem;
      box-sizing: border-box;
      h4 {
        color: rgba(255, 255, 255, 0.877);
        margin: 5px 0px;
        margin-bottom: 10px;
        font-weight: 550;
      }

      p {
        color: rgba(255, 255, 255, 0.705);
        margin: 5px 0px;
        font-size: 14px;
      }

      input {
        width: 95%;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.247);
        padding: 10px;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.438);
        font-size: 15px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.438);
        }
      }

      input,
      input::-webkit-input-placeholder {
        font-size: 12px;
      }

      &.withoutSpace {
        padding: 0rem;

        h4 {
          margin-top: 2rem;
          margin-left: 2rem;
        }
      }
    }
  }
}

.meetPopup {
  .modal {
    max-width: 60rem;

    .content {
      div {
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 0;
      }
    }

    .heading {
      padding-left: 2rem;

      h3 {
        color: var(--white);
      }
    }
  }
}

.popupButton {
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.144);
  }
}

.orange1 {
  min-width: 125px;
  padding: 10px 10px;
  background-color: var(--orange);
  color: var(--white);
  font-size: 13px;
  border-radius: 4px;
  margin: 5px;
  font-weight: 550;
  border: 1px solid transparent;
  @include flex-align(center, center);
  cursor: pointer;

  &:focus,
  &:hover {
    background: var(--white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--orange);
    color: var(--orange);

    svg {

      g,
      path {
        fill: var(--orange);
      }
    }
  }
}

.grayBtn1 {
  background: rgba(61, 61, 61, 0.5);
  border: 1px solid rgba(136, 139, 140, 0.12);
  color: #ffffff;
  // min-width: 125px;
  min-width: 100%;
  padding: 10px 10px;

  &:hover {
    // background: var(--white) 0% 0% no-repeat padding-box;
    // border: 1px solid rgba(0, 0, 0, 0.5);
    // color: rgba(0, 0, 0, 0.5);
    background: var(--white) 0% 0% no-repeat padding-box;
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
  
  }
}

.borderRadius {
  border-radius: 4px;
}