@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/colors";

.mainPopup {
  @include flex-align(center, center);
  padding-top: 100px;
  .content {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 50%;
    width: 100%;
    height: 670px;
    background-color: #1b1b1b;
    border-radius: 20px;
    @include flex-align(center, center);
    @include flex-direction(column);
    h1 {
      font-size: 42px;
      line-height: 44px;
      color: var(--orange);
      font-weight: 600;
      letter-spacing: 0.4px;
      margin-top: 40px;
      span {
        color: #888888;
      }
      &.white {
        color: #ffffff;
      }
    }
    h3 {
      font-size: 25px;
      line-height: 44px;
      font-weight: bold;
      margin-top: 15px;
      color: #c1c1c1;
    }
    p {
      font-size: 16px;
      line-height: 26px;
      max-width: 650px;
      text-align: center;
      margin-top: 28px;
      color: #c1c1c1;
      margin-bottom: 50px;
    }
    .weblink {
      padding: 10px 5px;
      background-color: var(--orange);
      color: var(--white);
      font-size: 13px;
      border-radius: 4px;
      margin: 5px;
      min-width: 150px;
      font-weight: 550;
      border: 1px solid transparent;
      @include flex-align(center, center);
      cursor: pointer;
      &:focus,
      &:hover {
        background: var(--white) 0% 0% no-repeat padding-box;
        border: 1px solid var(--orange);
        color: var(--orange);
        svg {
          g,
          path {
            fill: var(--orange);
          }
        }
      }
      svg {
        margin-left: 10px;
        transform: scale(1.2);
        g,
        path {
          fill: var(--white);
        }
      }
    }
  }
}

@media (max-width: 1299px) {
  .mainPopup {
    .content {
      max-width: 60%;
      .tick {
        width: 50px;
        height: auto;
      }
      h1 {
        font-size: 30px;
        line-height: 40px;
      }
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 991px) {
  .mainPopup {
    .content {
      max-width: 75%;
    }
  }
}
