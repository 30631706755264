@import "../../../../assets/scss/_media-queries.scss";
@import "../../../../assets/scss/_mixins.scss";

.setInfo {
    display: inline-flex;
    width: 100%;
    min-height: 95vh;
    p {
        color: white;
    }
    .leftImg {
        width: 30%;
        background-position: 95%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .info {
        @include flex-align(center, center);
        width: 70%;
        .wrapper {
            width: 85%;
            max-width: 950px;
            padding-top: 20px;
            padding-bottom: 25px;
            h1 {
                color: white;
                padding-bottom: 30px;
                font-size: 40px;
            }
            .inputsUpload {
                @include flex-align(flex-start, space-between);
                width: 100%;
                gap: 15px;
                .inputs {
                    width: 70%;
                }
                .uploadResume {
                    @include flex-align(center,  space-evenly);
                    flex-direction: column;
                    border-radius: 5px;
                    padding: 30px 20px;
                    height: 70px;
                    width: 20%;
                    margin-top: 10px;
                    background: #1A1A1A;
                    border: 1px solid #383838;
                    svg {
                        width: 25px;
                        height: 25px;
                        color: #EC7600;
                    }
                }
            }
            .rightContent {
                padding: 20px 0px;
            }
            .paragraph {
                padding-left: 20px;
                padding-bottom: 15px;
            }
        }
    }
}

@include max(laptop) {
    .setInfo {
        flex-wrap: wrap;
        gap: 30px;
        min-height: 90vh;
        .leftImg {
            width: 100%;
            height: 250px;
        }
        .info {
            width: 100%;
            .wrapper {
                .content {
                    width: 80%;
                    margin: 0 auto;
                }
                h1 {
                    font-size: 35px;
                }
                .inputsUpload {
                    flex-wrap: wrap;
                    .inputs {
                        width: 100%;
                    }
                    .uploadResume {
                        width: 30%;
                    }
                }
            }
        }
    }
}