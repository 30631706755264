@import "../../../../assets/scss/media-queries";

.backButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 23%;
  margin-top: 150px;
  position: absolute;

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding-left: 5px;
  }
}

.container {
  width: 100%;
  max-width: 59%;
  min-width: 336px;
  border-radius: 12px;
  margin-right: 20px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #2e2e2e;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #3a3a3a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ec7600;
  }
  @include custom-max(1024) {
    width: 85%;
    max-width: 85%;
    height: 500px;
    margin: 0 auto;
  }
}

.containerPadding {
  margin-left: 23%;
  margin-top: 216px;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .infoMeeting {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    background-color: #0d0d0d;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: 30px;
    h2 {
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: lighter;
    }
    span {
      text-align: left;
      color: #a3a3a3;
      margin-top: 30px;
    }
    ul {
      list-style: circle;
      margin-top: 10px;
      padding-left: 20px;
      li {
        color: #a3a3a3;
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: auto;
}
