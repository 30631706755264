.wrapper{
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.4;
    width: 100%;
    color: rgba(256, 256, 256, 0.3);
    font-size: 24px;
    font-weight: 600;
}
