.protectedPanel {
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  color: var(--white);
  position: relative;
  .universalIcon {
    width: 218px;
    position: fixed;
    background-color: #090909;
    padding-top: 54px;
    padding-bottom: 5px;
    padding-left: 1.8%;
    z-index: 1;
    svg {
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
