@import "../../../../../assets/scss/mixins";
.containerSavePopup {
  background: linear-gradient(
      0deg,
      rgba(60, 60, 61, 0.54),
      rgba(60, 60, 61, 0.54)
    ),
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 25.53%,
      rgba(36, 36, 36, 0.2) 100%
    );
  border: 1px solid rgba(151, 151, 151, 0.2);
  backdrop-filter: blur(70px);
  border-radius: 4px;
  padding: 27px;
  width: 410px;
  box-sizing: content-box;
  .header {
    @include flex-direction(row);
    @include flex-align(center, space-between);
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.23px;
      color: #ffffff;
    }
  }
  .content {
    @include flex-direction(column);
    @include flex-align(flex-start, center);
    span.time {
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.29px;
      margin: 24px 0;
    }
    > div.main {
      width: 100%;
      @include flex-direction(column);
      @include flex-align(flex-start, center);
      label {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.23px;
        margin-bottom: 5px;
        color: #ffffff;
        position: relative;
        input {
          margin-top: 5px;
          width: 100%;
          height: 38px;
          background-color: transparent;
          border-radius: 5px;
          margin-bottom: 15px;
          color: white;
          padding: 0 34px 0 10px;
          box-sizing: border-box;
          box-shadow: none !important;
          border: 1px solid rgb(121, 121, 121) !important;
        }
        svg{
          position: absolute;
          right: 8px;
          top: 32px;
          cursor: pointer;
        }
      }

      .nameError{
        color: #ec7600;
        padding-bottom: 6px;
        font-size: 15px;
        font-weight: bold
      }
    }
    .button {
      width: 100%;
      height: 44px;
      left: 1px;
      top: 1px;
      background: #ec7600;
      border-radius: 4px;
      margin-top: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.23px;
      text-transform: capitalize;
      color: #ffffff;
      cursor: pointer;
    }

    .disableBtn {
      background: rgb(149, 148, 148)!important;
    }
  }
}


.tagsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  list-style-type: none;
  scroll-behavior: smooth;
  .tagSpan {
    white-space: nowrap;
    height: 28px;
    padding: 0 8px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    background-color: #282828de;
    color: gray;
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}