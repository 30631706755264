@import "../../../../css/globals.scss";
.topNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  padding: 10px;
  svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  span {
    padding-left: 10px;
  }
}
.grid {
  max-width: 50%;
  // height: auto;
  // max-height: 500px;
  border-radius: 5px;
  padding: 10px 10px 20px;
  margin: 20px auto;
  position: relative;
  bottom: 80px;
.dateHolder{
  padding-left: 30px;
  margin-bottom: 10px;
  margin-top: 0;
}
  .polls {
    line-height: 26px;
    bottom: 10px;

    .headingText {
      font-family: "Quentin", Raleway;
      font-size: 3rem;
      margin: 40px 30px 0px 30px;
    }

    .pollsWrapper {
      position: relative;
      pointer-events: auto !important;
      background-color: #2121239c;
      border-radius: 50px;
      min-height: 70vh;
      max-height: 75vh;
      overflow-y: auto; 
      background-image: url(https://res.cloudinary.com/dsg2ktuqk/image/upload/v1692114544/wizr%20x/Group_40437_yhcp8e.png);

      .header {
        font-size: 16px;
        font-weight: bold;
      }

      .content {
        padding: 20px;
        box-sizing: border-box;
        padding: 15px 40px;

        .orderedPollQuestions {
         li {
          padding: 10px;
         }
        }
        .choiceVotes {
          margin-top: 2px;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-radius: 20px;
          background-color: rgba(81, 81, 81, 0.2784313725);
          backdrop-filter: blur(20px);
        }

        .textAnswer {
          flex-direction: column;
          display: flex;
          // justify-content: space-between;

          .textAnswerText {
            background-color: #51515147;
            backdrop-filter: blur(20px);
            width: 100%;
            border-radius: 20px;
            padding: 6px 14px;
            color: #d0d0d0;
            font-size: 15px;
            margin-top: 2px;
            box-sizing: border-box;
          }

          .textAnswerUsername {
            color: #8e9294;
            width: 100%;
            font-style: italic;
            font-size: 12px;
            margin-top: 4px;
            margin-right: 10px;
            text-align: right;
          }
        }
        .sliderMainContainer {
          .slideRangeAnswer {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .voteCount {
            color: #8e9294;
            width: 100%;
            font-style: italic;
            font-size: 12px;
            margin-top: 4px;
            margin-right: 10px;
            text-align: right;
          }
        }

        .voterStats {
          color: #ec7600;
          cursor: pointer;
        }

        .voteData {
          display: flex;
          justify-content: space-between;
          margin-top: 2px;
          padding: 10px;
          box-sizing: border-box;
        }

        .vote {
          color: #8e9294;
        }

        
        .voteStatsContainer {
          .questionOption {
            padding: 5px 0;
            font-weight: bold;
          }
          .voteQuestion {
            padding: 5px 0;
            font-size: 17px;
          }
          .voteStatsNav {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 12px;
            padding: 10px 0;
            svg {
              width: 23px;
              height: 23px;
              cursor: pointer;
            }

            span {
              padding-left: 10px;
            }
          }

          ul {
            list-style-type: disc;
            margin: 0;
            padding: 0 0 0 10px;
            padding-left: 20px;
            list-style-position: inside;
          }

          .optionNumber {
            font-weight: normal;
            padding-right: 4px;
          }
        }
      }
    }
  }

  .infoMeeting {

    .header {
      height: 150px;
      width: 100%;
      background-image: url("../../../../assets/images/wall-paper-polls.png");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      display: flex;

      .centered {
        margin: auto;
        padding-top: 20px;
        line-height: 26px;

        ul {
          list-style-type: disc;
          margin: 0;
          padding: 0 0 0 10px;
          list-style-position: inside;
        }
      }
    }
  }
}

.listTitle{
  font-size: 20px;
  line-height: 50px;
}

.list{
  li{
    font-size: 18px;
    line-height: 30px;
  }
}

.desc{
  font-size: 20px;
  padding-top: 20px;
}