@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/media-queries";

.row {
    display: grid;
    grid-template-columns: 2fr 1.3fr 2fr 1.3fr 1.5fr 0.5fr;
    &:hover {
        background-color: #1b1b1b;
        border-radius: 8px;
    }
    td {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        @include flex-align(center, space-between);
        gap: 8px;
        p {
            background-color: rgb(6, 147, 218);
            border-radius: 50%;
            padding: 5px;
            font-size: 14px;
            width: 20px;
            height: 20px;
            @include flex-align(center, center);

        }
        .tdContent {
            @include flex-align(center, center);
            gap: 10px;
        }
        &.organiser {
            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }
        }
        &.lastItem {
            justify-content: flex-end;
            gap: 0px;
            svg {
                width: 16px;
                height: 16px;
                fill: #FFFFFF;
                margin-right: 10px;
            }
            div {
                width: 5px;
                height: 5px;
                margin: 2px;
                background-color: #FFFFFF;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
    @include max(desktop) {
        td {
            font-size: 12px;
        }
    }
    @include custom-max(1000) {
        td {
            font-size: 14px;
        }
    }
    @include custom-max(1300) {
        grid-template-columns: 1.4fr 1.1fr 1.7fr 0.8fr 1.5fr 0.3fr;
    }
}
