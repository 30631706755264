@import "../../../../assets/scss/_mixins.scss";
.sharedButton {
  padding: 8px 20px;
  background-color: var(--orange);
  color: var(--white);
  font-size: 12px;
  border-radius: 30px;
  min-width: 100px;
  font-weight: 500;
  border: 1px solid transparent;
  @include flex-align(center, center);
  cursor: pointer;
  &:focus,
  &:hover {
    background: var(--white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--orange);
    color: var(--orange);
    svg {
      g,
      path {
        fill: var(--orange);
      }
    }
  }
  svg {
    margin-left: 10px;
    transform: scale(1.2);
    g,
    path {
      fill: var(--white);
    }
  }

  //white-button
  &.whiteBtn {
    background-color: var(--white);
    color: var(--orange);
    border: 1px solid var(--orange);
    &:hover {
      background-color: var(--orange);
      color: var(--white);
    }
  }

  //gray-button
  &.grayBtn {
    background: rgba(61, 61, 61, 0.5);
    border: 1px solid rgba(136, 139, 140, 0.12);
    color: #8f8c8c;
  }
  &.lightGrayBtn{
    background: #434343;
    border: 1px solid #434343;
    color: rgba(255, 255, 255, 0.727);
    &:hover {
      background: var(--white);
      color: #434343;
      border-color:#434343;
    }
  }
  &.scheduleBtn{
    background: #454545;
    color: rgba(255, 255, 255, 0.727);
    font-size: 13px;
    &:hover {
      background: #454545;
      color: rgba(255, 255, 255, 0.727);
      border: 1px solid #454545;
      opacity: .7;
    }
  }
  &.grayBtn1 {
    background: rgba(61, 61, 61, 0.5);
    border: 1px solid rgba(136, 139, 140, 0.12);
    color: #ffffff;
    min-width: 125px;
    padding: 10px 10px;
    &:hover {
      background: var(--white) 0% 0% no-repeat padding-box;
      border: 1px solid rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
    }
  }
  &.orange1 {
    min-width: 125px;
    padding: 10px 10px;
  }
  &.recordbtn{
    background: linear-gradient(180deg, #453882 0%, #362C65 100%);
  }
}

.button {
  min-width: 125px;
  padding: 10px 10px;
}

.sharedButton{
  &:hover{
    background-color: #ec7600;
    color: white;
    opacity: 0.9;
  }
}

body:global(.purple-mode){
  .sharedButton{
    background-color: #507dd0;
    color: white;
  }
}
body:global(.green-mode){
  .sharedButton{
    background-color: #078369;
    color: white;

  }
}
body:global(.blue-mode){
  .sharedButton{
    background-color: #0175b2;
    color: white;

  }
}
body:global(.bright-mode){
  .sharedButton{
    background-color: #3e3e3f82;
    color: white !important;
    border: 1px solid transparent;
    &.lightGrayBtn{
      background-color: #ec7600;
    }
  }

}