@import "../../../../assets/scss/_media-queries.scss";

.addCard {
  height: 262px;
  background: #222222;
  mix-blend-mode: normal;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;

  .add {
    display: block;
    width: 50px;
    height: 50px;
    background-color: orange;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .plus {
      transform: scale(2);
    }
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.23px;
    color: #fcfdff;
  }
}

.card {
  height: 262px;
  background: #222222;
  mix-blend-mode: normal;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  position: relative;

  .dotsContainer {
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 10px;

    .menu {
      text-align: start;
      top: 30px;
      left: 10px;
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 150px;
      background: #272727;
      border: 1px solid #343434;
      border-radius: 4px;
      z-index: 10;

      ul {
        li {
          padding: 5px 0px 5px 12px;
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.23px;
          color: #fcfdff;

          &:hover {
            background: #44494a;
          }
        }
      }
    }

    .dots {
      display: inline-block;
      background: #696d6e;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }
  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.45px;
    color: #d4d9db;
    user-select: none;
  }

  .info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.23px;
    color: #ffffff;
    user-select: none;
    width: 74%;
    padding: 10px 0px;
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
}


@include custom-max(1450) {
  .addCard, .card{
    height: 190px;
  }
  .card{
    .info{
      width: 100%;
      font-size: 12px;
    }
    .name{
      font-size:16px;
    }
  }
}