
.videoRecords {
    > button {
        width: 400px;
        height: 44px;
        left: 1px;
        top: 1px;
        background: #ec7600;
        border-radius: 4px;
        margin-top: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.23px;
        text-transform: capitalize;
        color: #ffffff;
        cursor: pointer;
    }
    video {
        position: absolute;
        z-index: 9;
    }
    .video {
        cursor: move; /* fallback: no `url()` support or images disabled */
        cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
        cursor: -moz-grab; /* Firefox 1.5-26 */
        cursor: grab;
    }
    .bottomRight {
        bottom: 50px;
        right: 12px;
    }
    .topRight {
        top: 100px;
        right: 12px;
    }
    .centerRight {
        top: 45%;
        right: 12px;
    }
    .bottomLeft {
        bottom: 50px;
        left: 12px;
    }
    .topLeft {
        top: 100px;
        left: 12px;
    }
    .centerLeft {
        top: 45%;
        left: 12px;
    }
    .circle {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
    .rectangle {
        width: 200px;
        height: 150px;
        object-fit: cover;
    }
    .roundedRectangle {
        width: 200px;
        height: 150px;
        border-radius: 10px;
        object-fit: cover;
    }
    .square {
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
    .roundedSqaure {
        width: 150px;
        height: 150px;
        border-radius: 10px;
        object-fit: cover;
    }
}
