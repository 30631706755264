.wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: rgba(256, 256, 256, 0.3);
    font-size: 16px;
    font-weight: 600;
    min-height: 30vh;
    svg{
        margin: 15px 0;
        transform: scale(1.5);
    }
    p{
        color: rgba(255, 255, 255, 0.567);
        font-weight: 400;
        font-size: 20px;
        margin-right: 8px;
    }
}
body:global(.purple-mode),body:global(.green-mode),body:global(.blue-mode){
    .wrapper{
        svg{
            g,path,ellipse{
                fill: white;
            }
        }
    }
}