.universal__wrapper {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: var(--contentBg);
    transition: all 0.20s ease-in-out;
    &.closedMenu {
      left: -250px ;
      transition: all 0.25s ease-in-out;
    }
  }
  
  .uni-menu-wrapper {
    position: unset !important;
    height: 100% !important;
    width: 100% !important;
    z-index: unset !important;
    background-color: var(--contentBg);
  }
  
  .menu_logo_btn {
    position: fixed;
    top: 115px;
    left: 15px;
    padding: 12px;
    background-color: rgba(30, 30, 30, 0.7);
    border-radius: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }