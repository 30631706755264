@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/media-queries";

.allaplicants {
  .cardContainer {
    @include flex-align(flex-start, flex-start);
    padding-left: 355px;
    flex-wrap: wrap;
    gap:10px;
    padding-right: 115px;
    .buttons {
      @include flex-align(center, center);
      flex-direction: column;
      flex-wrap: wrap;
      button {
        margin-bottom: 14px;
      }
      @include max(laptop) {
        flex-direction: row;
        gap: 15px;
        align-items: flex-end;
        margin: 10px 0px;
      }
    }
  }
}
body:global(.universalmenu-closed) {
  :local(.allaplicants) {
    .cardContainer {
      padding-left: 130px;
    }
  }
}
@media (max-width: 1099px) {
  .allaplicants {
    .cardContainer {
      padding-left: 290px;
      padding-right: 45px;
    }
  }
  body:global(.universalmenu-closed) {
    :local(.allaplicants) {
      .cardContainer {
        padding-left: 130px;
      }
    }
  }
}

@media (max-width: 991px) {
  .allaplicants .cardContainer{
    .buttons{
      flex-direction: column;
      align-items: center;
    }
  }
  body:global(.universalmenu-closed) {
    :local(.allaplicants) {
      .cardContainer {
        padding-left: 60px;
        padding-right: 43px;
      }
    }
  }
}