@import "../../../../assets/scss/mixins";
.card {
  margin-right: 20px;
  padding: 40px 35px;
  background-color: #1b1b1b;
  max-width: max-content;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  .title {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    h4 {
      color: #d4d9db;
      margin-bottom: 10px;
    }
  }
  .desc {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 28px;
    p {
      margin-bottom: 10px;
      color: #d4d9db;
    }
  }
}
.applicantCard {
  margin-right: 0px;
  padding-right: 0;
  padding-left: 0;
  background-color: transparent;
  .title {
    padding-right: 30px;
    h4 {
      font-weight: normal;
      color: #c1c1c1;
    }
  }
  .desc {
    p {
      font-weight: normal;
      color: #ec7600;
      &:nth-child(1) {
        text-decoration: underline;
      }
      &:nth-child(2) {
        text-decoration: underline;
      }
      &:nth-child(3) {
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 1099px) {
  .card {
    .title {
      font-size: 16px;
    }
    .desc {
      font-size: 16px;
    }
  }
}
