.row {
    display: grid;
    grid-template-columns: 2fr 1.3fr .8fr 1.5fr 0.8fr;
    padding: 0 10px;
    position: relative;

    &:hover {
        background-color: #33333366;
        border-radius: 8px;
        td {
            .checkmark {
                border: 1px solid #A5A5A5;
            }
        }
    }

    td {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        padding: 6px 0;
        align-self: center;

        &.organiser {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }

        .checkmark {
            border: none;
            border-radius: 4px;
            background-color: transparent;
            width: 12px;
            height: 12px;
            display: block;
            margin-left: 15px;
        }

        .name {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
            }
            img{
                width: 20px;
                height: 20px;
                padding-right: 5px;
            }
        }
        .emailContainer {
            display: flex;
            justify-content: start;
            align-items: center;
            .emailText {
                padding-left: 5px;
            }
        }

        .kebabContainer{
            button {
                background: none;
                border: none;
                position: relative;
                width: 30px;  /* Adjust as needed */
                height: 30px;  /* Adjust as needed */
                cursor: pointer;
                outline: none;
            }
            
            button > div {
                width: 6px;   /* Adjust as needed */
                height: 6px;
                background-color: #c7c7c7;  /* Adjust color as needed */
                border-radius: 50%; /* Makes each div circular */
                margin: 2px auto;  /* Centers the circle and provides spacing between */
            }
            
        }
    }
}

@media (max-width: 1099px) {
    .row {  
      td {
        font-size: 12px;
      }
    }
}

@media (max-width: 768px) {
    .dateColumn, .sizeColumn, .email  {
        display: none; /* Hide these column on mobile devices */
    }

    .nameColumn /* Add other column classes here as needed */ {
        width: 12.5rem; /* Adjust the width as needed */
    }
}
