@import "../../../../assets/scss/mixins";
.container {
    @include flex-align(center, center);
    @include flex-direction(column);
    margin-top: 80px;
  h1 {
    font-size: 42px;
    color: var(--white);
    margin-bottom: 20px;
  }
  h5{
      color: #696D6E;
      font-size: 25px;
      line-height: 44px;
      span{
          font-weight: normal;
      }
  }

  span {
    color: #44484a;
    padding-left: 5px;
    padding-right: 5px;
  }
}
