@import "../../../../assets/scss/_media-queries.scss";

.addCard {
  height: 202px;
  background: #222222;
  mix-blend-mode: normal;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
border-color: rgba(96, 96, 96, 0.416) !important;
margin-top: 4px;
  .add {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #E16407;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .plus {
      transform: scale(2);
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    padding: 0 1rem;
    text-align: center;
    letter-spacing: -0.23px;
    color: #fcfdff;
  }
}

.card {
  height: 202px;
  background: #222222;
  mix-blend-mode: normal;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  margin: 5px 0px;
  // max-width: 240px;

  button {
    position: absolute;
    padding: 5px;
    top: 20px;
    right: 20px;
    cursor: pointer;

    .dotsContainer {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      .menu {
        text-align: start;
        top: 30px;
        left: 10px;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 150px;
        background: #272727;
        border: 1px solid #343434;
        border-radius: 4px;
        z-index: 10;

        ul {
          li {
            padding: 5px 0px 5px 12px;
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.23px;
            color: #fcfdff;

            &:hover {
              background: #44494a;
            }
          }
        }
      }

      .dots {
        display: inline-block;
        background: #696d6e;
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }
    }
  }

  .name {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.45px;
    color: #d4d9db;
    user-select: none;
  }

  .info {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.23px;
    color: #c1c1c1;
    user-select: none;
    width: 74%;
    padding: 10px 0px;
  }

  .images {
    display: flex;

    .img {
      margin-right: -10px;
      border-radius: 50%;
      border: 3px solid #7171714c;
      width: 28px;
      height: 28px;
      object-fit: cover;
    }

    .number {
      border-radius: 50%;
      background: #696d6e;
      border: 2px solid #7674742c;
      position: relative;
      width: 28px;
      height: 28px;

      p {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: -0.23px;
        color: #ffffff;
      }
    }
  }
}

@include custom-max(1450) {

  .addCard,
  .card {
    height: 190px;
  }

  .card {
    .info {
      width: 100%;
      font-size: 12px;
    }

    .name {
      font-size: 16px;
    }
  }
}
body:global(.purple-mode){
  .card,.addCard{
    background-color: #2b364ae0;
    .add{
      background-color: rgba(255, 255, 255, 0.185);
    }
  }
}
body:global(.green-mode){
  .card,.addCard{
    background-color: #253c37cd;
    .add{
      background-color: rgba(255, 255, 255, 0.185);
    }
  }
}
body:global(.blue-mode){
  .card,.addCard{
    background-color: #123d5ec0;
    .add{
      background-color: rgba(255, 255, 255, 0.185);
    }
  }
}
body:global(.bright-mode){
  .card,.addCard{
    background-color: #d0d0d0;
*{
  color: rgb(77, 77, 77);
}
  }
}