:root {
  --white: #ffffff;
  --black: black;
  --orange: #ec7600;
  --dark-color: #0d0d0d;
  --dark-gray: #151515;
  --light-gray-v1: #d1d2d3;
  --light-gray-v2: #c1c1c1;
  --dark-gray-v1: #333333;
  --dark-gray-v2: #1c1c1c;
  --light-gray-v3: rgba(255, 255, 255, 0.445);
  --gray: #A5A5A5;
}
