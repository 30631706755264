@import "../../../../assets/scss/_media-queries.scss";

.container {
  width: 100%;
  min-height: 100vh;
  // background-color: rgb(21, 21, 21);
  display: flex;
  justify-content: center;

  .center {
    width: 70%;
  }
  &.teamsContainer{
    margin-left: 5.2%;
    .center {
      width: calc(100% - 410px);
      transition: 0.2s ease;
      margin-top: -15px;
    }
  }
}
@include custom-max (1024) {
  .container.teamsContainer {
    .center {
      width: 100%;
      padding-right: 43px;
      padding-left: 285px;
    }
  }
  body:global(.universalmenu-closed) {
    :local(.teamsContainer) {
      .center {
        padding-left: 130px;
      }
    }
  }
}

@include max(tablet) {
  body:global(.universalmenu-closed) {
    :local(.teamsContainer) {
      .center {
        padding-left: 62px;
      }
    }
  }
}

