/* styles.css */
.slider-container {
    position: relative;
    width: 240px;
    height: 10px;
    background-color: white;
    border-radius: 10px;
  }
  
  .slider-track {
    position: absolute;
    top: 50%;
    height: 10px;
    border-radius: 10px;
    background-color: #EC7600;
    transform: translateY(-50%);
  }
  