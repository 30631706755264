@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/_media-queries.scss";

.interviewSetup2{
    @include flex-align(center, center);
    min-height: 70vh;
    flex-direction: column;
    h1{
        padding-bottom: 20px;
        font-size: 30px;
    }
    p{
        color: #FCFDFF;
    }
    .inputIcon{
        border-radius: 5px;
        padding: 10px;
        background: #1A1A1A;
        border: 1px solid #383838; 
        margin-top: 10px;
        margin-bottom: 20px;
        @include flex-align(center, space-between);
        input[type=text]{
            background-color: transparent;
            border: none;
            width: 95%;
            height: inherit;
            color: #ffffff71;
        }
        svg{
            width: 15px;
            height: 15px;
            fill: #4C4C4C;
        }
    }
    .success{
        color: var(--orange);
        font-size: 13.5px;
    }
    .selectOptions{
        cursor: pointer;
        border-radius: 5px;
        // padding: 10px;
        position: relative;
        background: #1A1A1A;
        border: 1px solid #383838; 
        margin-top: 10px;
        margin-bottom: 30px;
        select{
            background-color: transparent;
            border-radius: 5px;
            width: 100%;
            border: none;
            color: #ffffff71;
            option{
                background: #151515;
            }
            option:hover{
                background: #313131;
            }
        }
        .title{
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 10px;
            p{
            color: #767676;
            font-size: 14px;
            }
            svg{
                margin-top: 5px;
                path, g{
                    fill: #767676;
                }
            }
        }

        .dropdown{
            position: absolute;
            z-index: 99999;
            padding-top: 5px;
            padding-bottom: 5px;
            width: 100%;
            height: auto;
            overflow-y: auto;
            background-color: #212121 !important;
            // border: 1px solid #c1c1c1;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            p{
                font-size: 14px;
                padding-left: 15px;
                padding-bottom: 8px;
                padding-top: 8px;
                font-weight: lighter;
                &:hover{
                    background-color: #3e3e3e;
                  }
            }
            .content{
              font-size: 14px;
              font-weight: lighter;
              padding-left: 20px !important;
              margin-bottom: 0;
              padding-top: 5px;
              padding-bottom: 7px;
              &:hover{
                background-color: #3e3e3e;
              }
            }
          }
    }
    .owner{
        @include flex-align(center, flex-start);
        gap: 10px;
        svg{
            fill:var(--light-gray-v2);;
        }
    }
    h4{
        font-size: 20px;
        text-align: center;
        padding-top: 40px;
    }
    .info{
        width: 350px;
        color:var(--light-gray-v2);;
        line-height: 1.5em;
        font-size: 20px;
        padding-top: 14px;
    }
    .buttons{
        max-width: 87%;
        width: 100%;
    }
}

@include max(laptop) {
    .interviewSetup2 .buttons{
        padding-top: 45px;
        @include flex-align(center, center);
        padding-right: 0px;
    }
}