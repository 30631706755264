@import "../../../../assets/scss/_mixins.scss";
.inputButton{
    // margin: 1rem 2rem 0rem;
    margin: 0rem 0rem 1rem 0rem;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.247);
    border-radius: 10px;
    .buttonSend{
        width: 155px;
        background-color: #333333;
        @include flex-align(center, center);
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        cursor: pointer;
        border-left: 1px solid rgba(255, 255, 255, 0.247);
        transition: 1s ease;
        &:hover{
            background: #353535;
        }
    }
    input{
        border: none!important;
    }
}