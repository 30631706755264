@import "../../../../../assets/scss/_media-queries.scss";

.inputGroup {
  display: flex;
  flex-direction: column;
  label {
    color: var(--light-gray-v1);
    font-size: 14px;
  }
  input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid var(--light-gray-v3);
    color: var(--light-gray-v3);
    font-size: 12px;
    margin: 10px 0px;
    background: transparent;
    border-radius: 5px;
    &:focus {
      border: none;
      border-bottom: 1px solid var(--orange);
      border-radius: 0;
    }
    &::placeholder {
      color: var(--light-gray-v3);
    }
  }
}

@include min(laptop) {
  .inputGroup {
    label {
      font-size: 16px;
    }
    input {
      width: 100%;
      font-size: 14px;
    }
  }
}
