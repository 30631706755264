@import "../../../../assets/scss/_media-queries.scss";

.searchContainer {
  width: 500px;
  height: 44px;
  position: relative;
  overflow: hidden;
  border-radius: 24px;

  input {
    width: 100%;
    height: 100%;
    background-color: #222222;
    color: #8e9294;
    border: none;

    padding: 0px 16px;
  }
  @include max(laptop) {
    width: 100%;
  }
  &.applicants-search{
    width: 450px;
    @include custom-max(1024) {
      width: 410px;
    }
    @include max(tablet) {
      width: 200px;
    }
  }
}
