@import "../../../../../assets/scss/_mixins.scss";
@import "../../../../../assets/scss/_media-queries.scss";

.container {
  @include flex-align(none, center);
  width: 100%;
  flex-direction: column;
  border-radius: 12px;
  margin-top: 80px;

  .backButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 20px;
    padding-bottom: 30px;
    z-index: 10;
  
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  
    p {
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      padding-left: 5px;
    }
  }

  .videoPart {
    position: relative;
    width: 100%;
    //height: 100%;
    //@include flex-align(center, center);
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
    }

    div {
      z-index: 1;

      video {
        border-radius: 0.7rem;
        z-index: -1;
      }
    }

    .controlGroup {
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;

      .controlsLeft {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: left;
      }

      .controlsRight {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        column-gap: 15px;
      }
    }
  }

  .comments {
    @include flex-align(center, space-between);
    //width: 100%;
    padding: 10px 5px;
    position: relative;

    h2 {
      font-size: 0.8rem;
      font-weight: 550;
      color: #c1c1c13f;

      &:hover {
        color: var(--white);
      }
    }

    .inlineButtons {
      @include flex-align(center, center);
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      background-color: #272727;
      border-radius: 10px;
      min-width: 170px;
      position: absolute;
      z-index: 99;
      right: -11rem;
      bottom: 0.7rem;

      button {
        cursor: pointer;
        padding: 7px 20px;
        font-size: 17px;
        text-align: left;
      }
    }

    .dots {
      width: 40px;
      height: 30px;
      background-color: rgba(128, 128, 128, 0.137);
      border-radius: 0.4rem;
      cursor: pointer;
      @include flex-align(center, center);
      margin-left: 10px;

      div {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin: 0 2px;
        background-color: var(--white);
      }
    }

    .paragraph {
      white-space: nowrap;
      margin-top: 4px;
      font-size: 12px;
      @include flex-align(center, center);
      color: var(--light-gray-v2);
      padding: 0 10px;

      &:not(:last-child) {
        border-right: 1px solid rgba(128, 128, 128, 0.425);
      }

      svg {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .comment {
    margin: 1rem 0;
    min-width: 50rem;

    p {
      color: rgb(204, 204, 204);
      line-height: 22px;
    }

    img {
      max-width: 30px;
    }
  }

  .commentsPart {
    @include flex-align(flex-start, flex-start);
    width: 56.5%;
    margin-top: 0px;
    flex-direction: column;

    .title {
      @include flex-align(center, flex-start);
      margin: 0.2rem 0;
    }

    img {
      max-width: 40px;
    }

    h4 {
      font-weight: 400;
      color: rgb(216, 216, 216);
      margin-left: 0.5rem;
    }

    textarea {
      min-width: 65rem;
      min-height: 6rem;
      background: none;
      outline: none;
      display: block;
      border: none;
      border: 0.1px solid rgba(128, 128, 128, 0.226);
      border-radius: 5px;
      color: white;
      padding: 1rem;
      font-size: 15px;
      margin: 1rem 0;
    }

    button {
      margin: 0;
      margin-bottom: 1rem;
      cursor: pointer !important;

      p {
        cursor: pointer;
      }
    }
  }
}

.recordedVideo {
  max-width: 60rem;
  margin: 0;

  .videoPart {
    width: 100%;
    margin-top: 10px;
    position: relative;

    .controlGroup {
      display: flex;
      width: 100%;
    }
  }

  .comments {
    //width: 100%;
  }
}

@include min(laptop) {
  .container {
    //margin-left: 5rem;

    .comments {
      h2 {
        font-size: 1.1rem;
      }

      p {
        margin-top: 10px;
        font-size: 14px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .heightAuto {
    min-height: auto;
  }


}

@include min(desktop) {
  .container {
    margin-left: 0;
  }
}

body:global(.universalmenu-closed) {
  :local(.container) {
    margin-left: 0;

    .videoPart {
      position: relative;

      .controlGroup {
        display: flex;
        width: 100%;
      }
    }

    .comments {
      //width: 100%;
    }
  }
}

@include min(laptop) {
  body:global(.universalmenu-closed) {
    :local(.container) {
      margin-left: 0;

      .videoPart {
        position: relative;

        .controlGroup {
          display: flex;
          width: 100%;
        }
      }

      .comments {
        @include flex-align(center, space-between);
        //width: 100%;
        padding: 10px 5px;
        position: relative;

        h2 {
          font-size: 1.1rem;
          font-weight: 550;
        }

        .inlineButtons {
          @include flex-align(center, center);
        }

        .dropdown {
          display: flex;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          background-color: #272727;
          border-radius: 10px;
          min-width: 170px;
          position: absolute;
          z-index: 99;
          right: -11rem;
          bottom: 0.7rem;

          button {
            cursor: pointer;
            padding: 7px 20px;
            font-size: 17px;
            text-align: left;
          }
        }

        .dots {
          width: 40px;
          height: 30px;
          background-color: rgba(128, 128, 128, 0.137);
          border-radius: 0.4rem;
          cursor: pointer;
          @include flex-align(center, center);
          margin-left: 10px;

          div {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: 0 2px;
            background-color: var(--white);
          }
        }

        .paragraph {
          margin-top: 10px;
          @include flex-align(center, center);
          color: var(--light-gray-v2);
          padding: 0 10px;

          &:not(:last-child) {
            border-right: 1px solid rgba(128, 128, 128, 0.425);
          }

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

//New Styles

.my-data {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  /* column-gap: 3px; */
  list-style-type: none;
}

.single-video-footer {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  margin-top: 8px;
  margin-bottom: -2px;
  margin-left: 15px;
  margin-right: 15px;
  color: white;
  font-family: Roboto;
  font-size: 14px;
}

.right-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.dots {
  border: 1px solid;
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.detail-image {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: #0693da;
  margin-right: 10px;
}

.details {
  margin-left: 15px;
  display: flex;
}

.image-w {
  padding-left: 7px;
  padding-top: 8px;
  color: white;
}

.auth {
  display: grid;
  grid-template-rows: 0.5fr 0.5fr;
  row-gap: 2px;
  font-size: 12px;
  line-height: 16px;
}

.auth>p {
  margin-top: 2px;
  margin-bottom: 0rem;
  color: white;
}

.player {
  /* max-width: 10vw; */
  /* min-height: 540px; */
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  video {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }

  .controlGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    height: 60px;
  }

  .controlsLeft {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .controlsRight {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    column-gap: 15px;
  }

  .show-controls {
    width: 100%;
    height: 30%;
    z-index: 2;
    position: absolute;
    bottom: 0;
    cursor: default;
  }

  .controlsContainer {
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(22px);
    box-sizing: border-box;
    z-index: 5;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    opacity: 0;
    transition: all 0.5s ease-out 2s;
  }

  .controlsContainer:hover {
    opacity: 1;
    transition: all 0.2s ease-out;
  }

  .controlsContainerControl {
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(22px);
    box-sizing: border-box;
    z-index: 5;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    opacity: 0;
    transition: all 0.5s ease-out 2s;
  }

  select,
  option {
    cursor: pointer;
  }

  select {
    border: none;
    font-size: 16px;
    position: relative;
    top: -2.5px;
    border-radius: 5px;
    color: white;
    background: transparent;
  }

  select:focus {
    outline: none;
  }

  input[type="range"] {
    position: absolute;
    -webkit-appearance: none;
    border-radius: 20px;
    height: 4px;
    width: 100%;
    background: linear-gradient(90deg,
        rgba(227, 227, 227, 1) 60%,
        rgba(227, 227, 227, 1) 60%);
  }

  input[type="Range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #ec7600;
  }

  .muteBtn {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .velocity {
    appearance: none;
    background: none;
    color: white;
    outline: none;
    border: none;
    text-align: center;
    font-size: 14px;
  }

  .playControls,
  .timer {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

.mutiple-videos {
  width: 300px;
}

.favourite {
  font-size: 20px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 1.6px;
  color: #bdbdbd;
}

.record-container-button {
  border: none;
  background: none;
  cursor: pointer;
}

.record-container-button svg {
  outline: none;
  pointer-events: none !important;
}

@include custom-max(768) {
  .wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
}
