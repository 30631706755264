.rti--container {
  margin: 0 !important;
  background-color: transparent !important;
  max-height: calc(100% - 5px) !important;
  width: 100% !important;
  border: none !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }
  .rti--tag {
    background-color: #ec7600 !important;
    button {
      color: white !important;
      padding-top: 2px;
      padding-right: 2px;
      padding-left: 4px;
    }
  }
  .rti--input {
    background-color: transparent !important;
      color: white !important;
  }
}
