@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/_media-queries.scss";
@import url('../../../../assets/scss/_colors.scss');

.container {
  @include flex-align(flex-start, flex-start);
  flex-direction: column;
  .frameOptions {
    text-align: left;
    width: 100%;
    margin: 1rem 0;
    padding-bottom: 10px;
    button {
      font-weight: 500;
      font-size: 1.1rem;
      cursor: pointer;
      margin: 0 10px;
      opacity: 0.5;
      &.activeBtn {
        opacity: .8;
      }
    }
  }
  .basicCont{
    width: 100%;
    display: flex;
    gap: 100px;
    .inputs{
      width: 100%;
    }
  }
  .frame {
    border-radius: 20px; 
    width: 100%;
    .checkPart {
      margin-top: 2rem;
      margin-bottom: 5.5rem;
    }
    .advancedPart {
      margin-top: 2rem;
    }

    .btns {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      margin-top: 1.6rem;
      button{
        margin: 0 -3px;
        background-color: #434343 ;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 400;
    }
    p {
      font-size: 13px;
      color: var(--white);
      margin: 10px 0px;
      opacity: 0.7;
    }
    .check {
      margin-left: 2rem;
    }
    .availability {
      margin: 30px 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: calc(100% - 25px);
      svg {
        margin-right: 10px;
        transform: scale(1.2);
      }
    }

    .truncateContainer {
      display: flex;
      flex-direction: row;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .textAndCopy {
        flex-direction: row;
        display: flex;
        align-items: center;
      }

      .truncate {
        width: 345px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        color: #b1afaf;
        border-bottom: 2px solid rgb(128, 128, 128);
        padding-right: 10px;
      }
      svg {
        cursor: pointer;
      }
    }
    .inlineOptions {
      display: inline-flex;
      justify-content: flex-start;
      gap: 60px;
    }
  }
}
.heading {
  display: flex;
  svg {
    margin-top: 8px;
    margin-right: 5px;
    transform: scale(1.2);
  }
  p {
    margin-top: 0px;
    margin-left: 5px;
  }
}

@include min(laptop) {
  .container {
    max-width: 50vw;
    margin: 0 auto;
    .frame {
      h2 {
        font-size: 19px;
      }
      p {
        font-size: 15px;
      }
      .availability {
        h3 {
          font-size: 17px;
        }
      }
      .frameOptions {
        button {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@include custom-max(1024) {
  .container {
    padding-left: unset;
    margin-left: unset;
    align-items: center;
    .frameOptions {
      width: 72%;
    }
  }
  body:global(.universalmenu-closed) {
    :local(.container) {
      padding-left: 0px;
    }
  }
}

body:global(.purple-mode){
  .container{
      .frame{
          background-color: #191b26c8;
      }
  }
}
body:global(.green-mode){
  .container{
    .frame{
      background-color: #09483a93;
    }
  }
}
body:global(.blue-mode){
  .container{
    .frame{
      background-color: #093f66cb;
    }
  }
}
body:global(.bright-mode){
  .container{
    .frame{
      background-color: #D3D2D2;
      *{
        color: rgb(57, 57, 57);
      }
      input{
        background-color: #ECECEC;
        &::placeholder{
          color: gray;
        }
      }
      input[type="radio"]{
        background: none;
      }
    }
  }
}



.mutiEmailLabel {
  color: var(--light-gray-v1)!important;
  margin-left: 7px!important;
  margin-top: 7px!important;
  margin-bottom: 7px!important;
  font-size: 15px!important;
  opacity: 1!important;
}

.formError {
  color: #ec7600!important;
  margin-left: 17px!important;
}


.startTime {
  color: #b1afaf
};

@include custom-max(768) {
  .container {
    padding-left: unset;
    align-items: center;
    .frameOptions {
      margin: 1rem 0;
      padding-bottom: 10px;
      width: 72%;
    }
    .basicCont{
      display: flex;
      flex-direction: column;
      gap: unset;
    }
  }
}
