.PhotoAndLogoutContainer {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  z-index: 2;
  .userImageContainer {
    position: relative;
    height: 120px;
    width: 120px;
    .userImg {
      position: relative;
      height: inherit;
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2E2E2E;
      border-radius: 50%;
      border: 2px solid rgba(151, 151, 151, 0.2);
      .cameraIcon {
        cursor: pointer;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: var(--orange);
        border-radius: 50%;
        path {
          fill: white;
        }
        &:hover {
          box-shadow: 0px 0px 3px 0px var(--orange);
        }
      }
    }
    .username {
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .closeBtn {
    a {
      height: inherit;
      width: inherit;
      padding: 12px 15px;
      background-color: var(--orange);
      color: white;
      border-radius: 4px;
      &:hover {
        box-shadow: 0px 0px 3px 0px var(--orange);
      }
    }
  }
}