.gridItems {
    padding-top: 15px;
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 50px;
    .item{
        width: 174px;;
    }
    .namePoints{
        display: flex;
        justify-content: space-between;
        p {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
        }
        .Points{
            div{
                width: 5px;
                height: 5px;
                background-color: var(--light-gray-v3);
                margin: 2px;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
    .rate{
        padding: 8px 0px;
    }
    .date{
        p{
            font-size: 14px;
        }
    }
}