// TimeInput.module.scss

.container {
    font-family: Arial, sans-serif;
    // padding: 20px;
    // border: 1px solid #ddd;
    padding-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
    width: 250px;
  }
  
  .time-input {
    display: flex;
    align-items: center;
    gap: 10px;
  
    input {
      width: 43px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      text-align: center;
      background: transparent;
      color: hsl(0deg 0% 100% / 44.5%);
      border: 1px solid hsla(0,0%,50%,.271);
      border-radius: 30px;
  
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }

  