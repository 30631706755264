.titleParagraph {
    margin-bottom: 30px;

    h4 {
        padding-bottom: 20px;
        font-size: 20px;
    }

    p {
        line-height: 25px;
    }

    p::before {
        content: "•";
        margin-right: 10px;
    }

}