@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/media-queries";

.topNavigation {
  width: 100%;
  background-color: var(--dark-color);
  @include flex-align(center, space-between);
  padding: 8px 0;
  position: fixed;
  z-index: 2;

  svg {
    margin-top: 2px;

    g,
    path {
      fill: #EC7600;
    }
  }

  img {
    max-width: 33px !important;
  }

  .dropdown {
    background-color: rgb(37, 37, 37);
    padding: 10px;
    position: absolute;
    margin-top: 8rem;
    right: 2rem;
    border-radius: 9px;

    button {
      background-color: transparent;
      text-align: left;
      align-items: flex-start;
      min-width: auto;
      width: max-content;
      font-size: 13px;
      width: 100%;
      justify-content: flex-start;
      border-radius: 4px;
      border: none;

      &:hover {
        border: none;
        background-color: rgba(128, 128, 128, 0.202);
      }
    }
  }

  .logoTitle {
    @include flex-align(center, center);

    p {
      font-weight: 500;
      letter-spacing: 0.4px;
      font-size: 20px;
      padding-left: 0rem;
      color: #EC7600;

      span {
        color: #c1c1c1;
      }
    }
  }

  .logo {
    cursor: pointer;
    padding: 0.1rem 2rem;
    @include flex-align(center, flex-start);

    svg {
      min-width: 25px;
      min-height: 25px;
    }

    h2 {
      font-size: 16px;
      font-weight: 500;
      color: white;
      margin-left: 1rem;

      span {
        font-weight: 700;
      }
    }
  }

  .info {
    padding: 0.1rem 1.2rem;
    @include flex-align(center, flex-start);
    gap: 10px;

    .plan {
      @include flex-align(center, center);
      cursor: pointer;
    }

    img {
      max-width: 35px;
      margin-right: -20px;

    }

    svg {
      transform: scale(1.4);
    }

    p {
      color: var(--orange);
      margin: 0px 1rem;

      span {
        color: var(--light-gray-v1);
      }
    }
  }
}

@include min(laptop) {
  .topNavigation {
    .logo {
      .svg {
        min-width: 35px;
        min-height: 35px;
      }

      h2 {
        font-size: 18px;
      }
    }
  }
}

body:global(.purple-mode),
body:global(.green-mode),
body:global(.blue-mode) {
  .topNavigation {
    * {
      color: white;
    }

    svg {

      g,
      path {
        fill: white;
      }
    }
  }
}

body:global(.purple-mode) {
  .topNavigation {
    background-color: #191B26;
  }
}

body:global(.green-mode) {
  .topNavigation {
    background-color: #121C19;
  }
}

body:global(.blue-mode) {
  .topNavigation {
    background-color: #01253B;
  }
}

body:global(.bright-mode) {
  .topNavigation {
    background-color: #2C2C2C;
  }
}