.transcripts {
  width: 100%;
  height: 100%;
  color: white;
  background-color: #090909;
}

.transcript {
  padding: 10px;
  margin-bottom: 10px;
}