@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/media-queries";

.comments {
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #2e2e2e;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #3a3a3a;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #ec7600;
    }

    @include flex-align(center, space-between);
    //width: 100%;
    padding: 10px 5px;
    position: relative;

    h2 {
        font-size: 0.8rem;
        font-weight: 550;
        color: var(--white);
    }

    .inlineButtons {
        @include flex-align(center, center);
    }

    .dropdown {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        border-radius: 10px;
        position: absolute;
        z-index: 99;
        right: -3rem;
        bottom: 0.7rem;
        border-radius: 4px;
        overflow: hidden;

        button {
            cursor: pointer;
            padding: 7px 20px;
            font-size: 17px;
            text-align: left;
        }
    }

    .dots {
        width: 40px;
        height: 30px;
        background-color: rgba(128, 128, 128, 0.137);
        border-radius: 0.4rem;
        cursor: pointer;
        @include flex-align(center, center);
        margin-left: 10px;

        div {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: 0 2px;
            background-color: var(--white);
        }
    }

    .paragraph {
        white-space: nowrap;
        margin-top: 4px;
        font-size: 12px;
        @include flex-align(center, center);
        color: var(--light-gray-v2);
        padding: 0 10px;

        &:not(:last-child) {
            border-right: 1px solid rgba(128, 128, 128, 0.425);
        }

        svg {
            margin-right: 5px;
            width: 18px;
            height: 18px;
        }
    }

    .comment {
        margin: 1rem 0;
        min-width: 50rem;

        p {
            color: rgb(204, 204, 204);
            line-height: 22px;
        }

        img {
            max-width: 30px;
        }
    }

    .mobileComments {
        display: none;
        
    }
    .largeComments {
        display: block;
    }

    @include custom-max(1024){
        .dropdown{
            right: -10px;
            bottom: 3rem;
        }
    }
}

@include min(laptop) {


    .comments {
        h2 {
            font-size: 1.1rem;
        }

        p {
            margin-top: 10px;
            font-size: 14px;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

}

.commentsPart {
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #2e2e2e;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #3a3a3a;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #ec7600;
    }

    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    @include flex-align(flex-start, flex-start);
    margin-top: 0px;
    flex-direction: column;

    .title {
        @include flex-align(center, flex-start);
        margin: 0.2rem 0;
    }

    img {
        max-width: 40px;
    }

    h4 {
        font-weight: 400;
        color: rgb(216, 216, 216);
        margin-left: 0.5rem;
    }

    textarea {
        width: 100%;
        //min-width: 65rem;
        box-sizing: border-box;
        //min-height: 6rem;
        background: none;
        outline: none;
        display: block;
        border: none;
        border: 0.1px solid rgba(128, 128, 128, 0.226);
        border-radius: 5px;
        color: white;
        padding: 1rem;
        font-size: 15px;
        margin: 1rem 0;
    }

    button {
        margin: 0;
        cursor: pointer !important;

        p {
            cursor: pointer;
        }
    }

    form {
        width: 100%;

        .commentWrapper {
            padding: 10px 5px;
            box-sizing: border-box;

        }
    }
}

@include min(laptop) {

    .comments {
        @include flex-align(center, space-between);
        //width: 100%;
        padding: 10px 5px;
        position: relative;

        h2 {
            font-size: 1.1rem;
            font-weight: 550;
        }

        .inlineButtons {
            @include flex-align(center, center);
        }

        .dropdown {
            display: flex;
            flex-direction: column;
            text-align: left;
            align-items: flex-start;
            border-radius: 10px;
            position: absolute;
            z-index: 99;
            // right: -11rem;
            // bottom: 0.7rem;
            border-radius: 4px;
            overflow: hidden;
            button {
                cursor: pointer;
                padding: 7px 20px;
                font-size: 17px;
                text-align: left;
            }
        }

        .dots {
            width: 40px;
            height: 30px;
            background-color: rgba(128, 128, 128, 0.137);
            border-radius: 0.4rem;
            cursor: pointer;
            @include flex-align(center, center);
            margin-left: 10px;

            div {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                margin: 0 2px;
                background-color: var(--white);
            }
        }

        .paragraph {
            margin-top: 10px;
            @include flex-align(center, center);
            color: var(--light-gray-v2);
            padding: 0 10px;

            .icons {
                width: 24px;
                height: 24px;
            }

            &:not(:last-child) {
                border-right: 1px solid rgba(128, 128, 128, 0.425);
            }

            svg {
                margin-right: 5px;
            }
        }
    }
}

@include custom-max(768) {
    .mobileComments {
        display: flex !important;
        margin: 0px 13px;

        p {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
    .largeComments {
        display: none !important;
    }
}
