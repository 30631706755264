@import "../../../../assets/scss/_mixins.scss";

.inviteCreate{
    @include flex-align(flex-end , space-between);
    background-color: #191919;
    padding:1.5rem  2rem;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 2rem;
    .iconText{
        @include flex-align(center,center);
        gap:15px;
        .globeStyle{
            background: #292929;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #707070;
            transition: 1s ease;
            cursor: pointer;
            svg{
                width: 18px;
                height: 18px;
            }      
            // &:hover{
            //     background: #191919;
            //     border-radius: 30%;
            // }
        }
        .whiteParagraph{
            color: white;
            font-size: 15px;
        }
    }
    .createLink{
        border: 1px solid #a9a9a9;
        border-radius: 10px;
        width: 100px;
        padding: 9px;
        text-align: center;
        cursor: pointer;
        transition: 1s ease;
        &:hover{
            background: #292929;
        }
    }
}