@import "../../../../assets/scss/_mixins.scss";

.selectOptions {
    width: 100%;
    background: var(--dark-gray-v1);
    border: 1px solid rgba(255, 255, 255, 0.247);
    border-radius: 10px;
    font-size: 15px;
    margin-top: 0rem;
    @include flex-align(center, center);

    &::placeholder {
        color: rgba(255, 255, 255, 0.438);
    }

    select {
        width: 97%;
        background: var(--dark-gray-v1);
        color: rgba(255, 255, 255, 0.438);
        border: none;
        padding: 10px;
    }
}

.originalSize {
    @include flex-align(flex-end, space-between);
    margin-top: 1rem;
}

.grayBtn1 {
    background: rgba(61, 61, 61, 0.5);
    border: 1px solid rgba(136, 139, 140, 0.12);
    color: #ffffff;
    // min-width: 125px;
    min-width: 100%;
    padding: 10px 10px;

    &:hover {
        background: var(--white) 0% 0% no-repeat padding-box;
        border: 1px solid rgba(0, 0, 0, 0.5);
        color: rgba(0, 0, 0, 0.5);
    }
}