@import "../../../../../assets/scss/_media-queries.scss";
.singleInput {
    input {
      width: 95%;
      font-size: 14px;
      padding: 0.8rem;
      background: #1A1A1A;
      border: 1px solid #383838;
      color: var(--light-gray-v3);
      border-radius: 5px;
      margin: 10px auto;
      &:focus {
        border-bottom: 1px solid var(--orange);
      }
    &::placeholder {
        color: var(--light-gray-v3);
    }
    }
}