.mainContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 550px;
  min-width: 220px;
  .nameContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    div {
      width: calc(100% - 20px);
      input {
        width: 100%;
      }
    }
    .lastName {
      display: flex;
      justify-content: flex-end;
    }
  }
  
  .titleInput {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .countryList {
    width: 100%;
  }
}
