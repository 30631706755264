.SettingElementContainer {
  display: flex;
  flex-direction: column;
  .header {
    margin: 20px 0 5px 20px;
    display: flex;
    align-items: center;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.23px;
      color: #8E9294;
    }
    button {
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
      transition: all 0.35s ease-in-out;
      &.default path {
        fill: var(--orange);
      }
      &.rotatedRight {
        rotate: (-90deg);
      }
      }
    }
  }
  .detailsAndToggler {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 10px 20px;
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.23px;
      color: rgba(252, 253, 255, 0.82);
    }
    .toggler {
      color: var(--orange);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.23px;
      text-transform: capitalize;
      cursor: pointer;
      &.noChanges {
        color: #8E9294;
        cursor: default;
      }
    }
  }
  .childrenWrapper {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 25.53%, rgba(36, 36, 36, 0.1) 100%), rgba(35, 35, 35, 0.9);
    border-radius: 17px;
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.075);
    transform-origin: top;
    transition: all 0.35s ease-in-out;
    padding: 10px 33px;
  }
}