.questionContainer {
    height: 300px;
    .edit {
        display: inline-flex;
        gap: 10px;
        align-items: center;
        padding-top: 20px;
        color: #4C4C4C;
        cursor: pointer;

        svg {
            fill: #4C4C4C;
        }

        &:hover {
            svg {
                fill: #EC7600;
            }

            button {
                color: #EC7600;
            }
        }
    }

    .dropdown {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        background-color: #272727;
        border-radius: 5px;
        width: 155px;
        visibility: hidden;
        margin-top: 5px;
        position: absolute;
        button {
            cursor: pointer;
            padding: 10px;
            font-size: 14px;
            text-align: left;
            width: 100%;
        }

        button {
            &:hover {
                background-color: rgba(255, 255, 255, 0.144);
            }
        }
    }

    .showDropdown {
        visibility: visible;
    }
}
.addQuestion {
    background: #262626;
    padding: 70px 20px 20px 20px;
    height: 125px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    border: dashed 3px #333333;
    p {
        color: #C1C1C1;
        line-height: 1.5em;
    }

    h3 {
        font-size: 20px;
        color: #FCFDFFD1;
    }

    .plusIcon {
        background-color: #787878;
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        svg {
            width: 25px;
            height: 25px;

            path {
                stroke: #262626;
            }
        }
    }

    &.plusIcon {
        justify-content: center;
        padding: 45px 20px 45px 20px;
        align-items: center;
    }
}