.comment,
.editCommentWrapper {
    position: relative;
    margin: 10px 0px;
    padding: 15px;
    border-radius: 12px;
    width: 100% !important;
    background-color: var(--dark-gray-v2);
    box-sizing: border-box;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #2e2e2e;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #3a3a3a;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #ec7600;
    }

    .edited {
        position: absolute;
        bottom: 10px;
        right: 15px;
        color: gray;
        font-size: 13px;
    }

    textarea {
        padding: 5px;
        border: 0.1 solid orange;
        resize: none;
    }

    .menuWrapper {
        position: absolute;
        top: 15px;
        right: 15px;

        .dots {
            display: flex;
            flex-direction: column;
            width: 15px;
            height: 20px;
            cursor: pointer;

            span {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: white;
                display: block;
                margin: auto;
            }
        }


    }

    .menu {
        top: 15px;
        //right: -110px;
        right: 15px;
        position: absolute;
        border-radius: 5px;
        border: 0.1px solid rgb(36, 36, 36);
        display: flex;
        flex-direction: column;
        background-color: rgb(34, 34, 34);

        .button {

            background: rgba(61, 61, 61, 0.5)!important;
            border: 1px solid rgba(136, 139, 140, 0.12)!important;
            color: #ffffff!important;
            min-width: 125px!important;
            padding: 10px 10px!important;

            &:hover {
                background: var(--white) 0% 0% no-repeat padding-box!important;
                border: 1px solid rgba(0, 0, 0, 0.5)!important;
                color: rgba(0, 0, 0, 0.5)!important;
            }
        }

    }

    .authorInfo {
        display: flex;

        .authorImage {
            margin-right: 10px;
        }

        .authorName {
            margin: 0;
            font-weight: bold;
        }

        .dateTime {
            color: gray;
            font-size: 14px;
        }
    }

    .authorComment {
        margin: 15px 0px;
        font-size: 15px;
        padding: 0px 50px;
    }

}
