@import "../../../../assets/scss/_mixins.scss";



.EdibleContentButton{
    // margin: 1rem 2rem 0rem;
    margin: 0rem 0rem 1rem 0rem;
    display: flex;
    border-radius: 10px;

    .buttonSend{
        width: 155px;
        background-color: #333333;
        @include flex-align(center, center);
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        cursor: pointer;
        transition: 1s ease;
        &:hover{
            background: #353535;
        }
    }
    .edibleContent{
        width: calc(100% - 154px);
        border: 1px solid rgba(128, 128, 128, 0.271);
        background: transparent;
        padding: .4rem;
        font-size: 14px;
        border-radius: 8px 0 0 8px;
        color: #ffffff71;
        transition: 0.35s ease;
        &.fullWidth {
          width: 96% !important;
          border-radius: 30px !important;
          margin-top: 5px;
        }
        .multiEmailInput {
          padding: 0;
          background: transparent;
          font-size: 1rem !important;
          border: none !important;
          span[data-placeholder] {
            top: 50%;
            transform: translateY(-50%);
            left: 5px !important;
            color: #ffffff71;
            font-size: 0.8rem;
          }
          input {
            color: #ffffff71;
            background: transparent;
        }
          .mails {
            background-color: #1f1f1f;
            color: rgb(204, 204, 204);
          }
        }
    }
}
body:global(.bright-mode){
  .edibleContent{
    background-color: #ECECEC;
  }
}