@import "../../../../../assets/scss/_media-queries.scss";

.OptionInput {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      margin: 5px ;
      margin-left: 0px;
      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        top: 0px;
        background-color: transparent;
        transition: 0.2s all linear;
        border: 1px solid var(--light-gray-v3);
        position: relative;
        &:checked {
          background-color: transparent;
          border: 3px solid var(--orange);
        }
      }
      label {
        font-size: 14px;
        margin-left: 10px;
        color: rgba(196, 196, 196, 0.619);
      }
    }
    &.information{
      flex-direction: row;
      padding-left: 15px;
      gap:15px;
    }
  }
  
  @include min(laptop){
    .OptionInput{
      div{
        input[type="radio"]{
          width: 15px;
          height: 15px;
          top: 3px;
        }
        label{
          font-size: 16px;
        }
      }
    }
  }
  