@import "../../../../assets/scss/mixins";

.interviewSetup2{
    padding-top: 80px;
    max-width: 87%;
    width: 100%;
    margin: 0 auto;
    h1{
        text-align: center;
    }
    .questionContainer{
        gap: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        padding-top: 45px;

    }
    .buttons{
        padding-top: 5%;
        width: 100%;
        padding-bottom: 15px;

    }
}
