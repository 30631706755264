.row {
    display: grid;
    grid-template-columns:  50px 1fr 1fr;
    padding: 0 10px;
    padding-bottom: 5px;
    position: relative;

    &:hover {
        background-color: #33333366;
        border-radius: 8px;
        td {
            .checkmark {
                border: 1px solid #A5A5A5;
            }
        }
    }

    td {
        font-size: 14px;
        line-height: 20px;
        color: #d2d2d2;
        padding: 6px 0;
        &.organiser {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }

        .checkmark {
            border: none;
            border-radius: 4px;
            background-color: transparent;
            width: 12px;
            height: 12px;
            display: block;
            margin-top: 3px;
            margin-left: 15px;
        }

        .name {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
            }
        }
        .emailContainer {
            display: flex;
            justify-content: start;
            align-items: center;
            .emailText {
                padding-left: 5px;
            }
        }
    }
}

@media (max-width: 1099px) {
    .row {  
      td {
        font-size: 12px;
      }
    }
  }
