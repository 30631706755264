@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/colors";
@import "../../../../assets/scss/media-queries";
.main {
  margin-left: 230px;
  .steps {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 87%;
    width: 100%;
    margin-left: 300px;
    margin: 50px auto;
    border-radius: 20px;
    background-color: #262626;
    @include flex-align(center, center);
    button {
      color: #575757;
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      transition: 0.3 ease-in-out;
      @include flex-align(center, center);
      &:after {
        content: "";
        width: 100px;
        height: 1px;
        background-color: #696868;
        top: 0;
        margin-left: 19px;
      }
      .icon {
        padding-right: 15px;
        padding-left: 20px;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
      &.active {
        color: var(--orange);
        svg {
          circle {
            fill: var(--orange);
          }
          g,
          path {
            fill: var(--white);
          }
        }
        &:hover {
          color: var(--orange);
          svg {
            circle {
              fill: var(--orange);
            }
            g,
            path {
              fill: var(--white);
            }
          }
        }
      }
      &:hover {
        color: #8b8b8b;
        svg {
          circle {
            fill: #777777;
          }
          g,
          path {
            fill: rgb(206, 206, 206);
          }
        }
      }
    }
  }
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 180px;
    .formHolder {
      width: 100%;
      max-width: 360px;
      textarea {
        width: 90%;
        padding: 0.8rem;
        border: 1px solid var(--light-gray-v3);
        color: var(--light-gray-v3);
        font-size: 12px;
        margin: 10px 0px;
        background: transparent;
        border-radius: 5px;
        resize: none;
        &:focus {
          border: none;
          border-bottom: 1px solid var(--orange);
          border-radius: 0;
        }
        &::placeholder {
          color: var(--light-gray-v3);
        }
      }
      h2 {
        color: #c1c1c1;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 30px;
        margin-top: 60px;
      }
      label{
        color: #c1c1c1;
      }
      input {
        margin-bottom: 30px;
      }
      .videointro {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        .switch {
          display: flex;
          span {
            padding-right: 10px;
            font-size: 16px;
            line-height: 20px;
            color: #4c4c4c;
          }
        }
        .switchbtn {
          position: relative;
          width: 28px;
          height: 15px;
          border-radius: 20px;
          background-color: #4c4c4c;
          margin-right: 10px;
          .circle {
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: #ec7600;
            border-radius: 60px;
          }
        }
      }
    }
  }
  @include max(laptop) {
    .form{
      margin-left: 0px;
      flex-wrap: wrap;
      .formHolder{
        max-width: 515px;
        h2{
          margin-top: 0px;
        }
        input,textarea{
          width: 95%;
        }
      }
    }
    .steps{
      button{
        font-size: 18px;
      }
    }
  }
  @include max(tablet) {
    .steps button::after{
      display: none;
    }
    .form .formHolder{
      max-width: 360px;
    }
  }
}

@include min(laptop) {
  .main {
    .form {
      .formHolder {
        textarea {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .steps {
    button {
      font-size: 15px;
      &::after {
        width: 60px;
      }
    }
  }
}

body:global(.universalmenu-closed) {
  :local(.main) {
    margin-left: 0;
  }
}
