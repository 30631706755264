.chats {
  height: calc(100% - 50px);
  color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 20px;
  padding: 20px 10px;
}
.chatsContainer{
  padding: 20px;
  background-color: #090909;
}