.homeCard {
  margin: 10px 15px;
  width: 100%;
  color: var(--light-gray-v2);
  text-align: left;
  min-height: 320px;

  &:hover {
    h3 {
      color: white;
    }
    img {
      transform: scale(1.05);
    }
  }

  .active {
    visibility: visible !important;
  }

  .cardContent {
    text-align: center;
  }

  .imageContent {
    position: relative;
  }

  img {
    width: 100%;
    max-width: 600px; /* Set the maximum width to maintain aspect ratio */
    height: auto; /* Let the height adjust proportionally to the width */
    border: 15px;
    border-radius: 20px;
    transition: 0.3s all ease-in-out;
  }

  .titleWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    margin: 15px 0 5px 0px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-align: center;
    transition: 0.3s all ease-in-out;
  }

  .text {
    text-align: center;
    margin-top: 0.5rem;
    max-height: 200px;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
    visibility: visible;
    max-width: 80%;
    margin: 0 auto;
    transition: 0.3s all ease-in-out; /* Add transition for font-size scaling */
  }
}

body:global(.bright-mode) {
  .homeCard {
    color: rgb(99, 97, 97);
  }
}
