.post {
  display: flex;
  width: 100%;
  margin-bottom: 35px;

  .left {
    margin-left: 16px;
    margin-right: 12px;
    .personImg {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    }
  }

  .right {
    margin-right: 16px;
    width: 100%;
    .personInfo {
      display: flex;
      justify-content: space-between;
      gap: 70px;
      padding-bottom: 10px;

      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;

        /* identical to box height, or 143% */
        letter-spacing: -0.23px;
        color: #ffffff;
      }

      span {
        color: #a5a5a5;
        font-size: 12px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.23px;
      color: #a5a5a5;
    }

    .preview {
      position: relative;
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      overflow: hidden;

      &:hover {
        background-color: #2e2e2e !important;
      }

      .image-container {
        width: 30px !important;
        height: 30px !important;
      }

      /*.LowerContainer {
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 106.2%;

          /* or 11px */
          //letter-spacing: 0.0012em;

          /* Background/white 
          color: #ffffff !important;
          margin: 0;
        }

        .Description {
          display: none;
        }

        .Secondary {
          margin-top: 0px;

          span {
            font-weight: 400;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 0.0012em;
            text-decoration-line: underline;
            color: #ffffff !important;
          }
        }
      }*/
    }
  }
}
