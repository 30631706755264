  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
  }
  .restorePopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30%;
    width: 60%;
    max-width: 500px;
    min-width: 250px;
    padding: 20px 5%;
    background-color: inherit;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    background: rgba(35, 35, 35, 1);
    border-radius: 17px;
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.075);
    .buttons {
      display: flex;
      justify-content: space-around;
      button {
        padding: 8px 15px;
        border-radius: 8px;
        background-color: var(--orange);  
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
        &:nth-child(2) {
          background-color: #8E9294;
        }
      }
    }
  }
