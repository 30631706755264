.SidebarFixedContainer {
  box-sizing: border-box;
  position: fixed;
  top: 200px;
  bottom: 38px;
  left: 10px;
  width: 220px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 25.53%, rgba(36, 36, 36, 0.1) 100%), rgba(35, 35, 35, 0.9);
  border-radius: 17px;
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.082);
  font-size: 0.9rem;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }
  .settingHeader {
    margin: 0 0 10px 5px;
  }
  .linkList {
    display: flex;
    flex-direction: column;
    .link {
      height: 45px;
      margin: 2px 0;
      display: flex;
      align-items: center;
      border-radius: 6px;
      svg {
        margin: 0 10px;
        height: 22px;
        width: 22px;
      }
      &:not(.active):hover {
        background-color: white;
        color: var(--orange);
        svg path {
          fill: var(--orange);
        }
      }
      &.active {
        background-color: #393735;
        color: var(--orange);
        svg path {
          fill: var(--orange);
        }
      }
    }
  }
}