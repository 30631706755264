@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/_media-queries.scss";

.teamSearchbar{
    @include flex-align(center, space-between);
    @include max(laptop) {
      gap: 20px;
      flex-wrap: wrap;
      .title{
        h2{
          font-size: 18px;
        }
      }
    }
    @include max(desktop) {
      .title{
        h2{
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
    .title {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    h2{
      font-size: 22px !important;
      font-weight: 400;
      margin-left: 5.8rem;
    }
      .dark {
        color: #5c6162 !important;
        margin-right: 10px;
      }
    }
}

@include min(tablet) {
    body:global(.universalmenu-closed ) {
      :local(.teamSearchbar) {
        margin-left: -165px;
    }
  }
}

@include custom-max(1024) {
  body:global(.universalmenu-closed ) {
    :local(.teamSearchbar) {
      margin-left: 0px;
    }
  }
}
body:global(.bright-mode){
  .teamSearchbar{
    *{
      color: rgb(99, 97, 97);
    }
  }
}
