@import "../../../../../assets/scss/_mixins.scss";
@import "../../../../../assets/scss/_media-queries.scss";

.container {
  @include flex-align(center, center);
}
.cardsContainer {
  display: grid;
  gap: 30px;
  max-width: 20rem;
  margin-top: 2rem;
  margin-right: 5rem;
}
@include custom-max(1024) {
  body:global(.universalmenu-closed) {
    :local(.container) {
      padding-left: 0px;
    }
  }
}
@include min(laptop) {
  .cardsContainer {
    max-width: 30rem;
    margin-right: 1rem;
  }
}
@include min(desktop) {
  .cardsContainer {
    max-width: 72%;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
}

@include max(laptop){
  .cardsContainer {
    margin-right: 0rem;
  }
}

@include custom-max(1300) {
  .cardsContainer {
    grid-template-columns: auto auto;
  }
  body:global(.rightmenu-closed) {
    :local(.container) {
      margin-left:  20%;
    }
  }
}

@include max(tablet){
  .cardsContainer {
    grid-template-columns: auto;
    img{
      width: 75%;
      height: 110px;
    }
  }
  body:global(.universalmenu-closed), body:global(.rightmenu-closed) {
    :local(.container) {
      .cardsContainer{
        grid-template-columns: auto auto;
        max-width: 100%;
      }
    }
  }
}
