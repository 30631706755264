.container {
    width: calc(100% - 600px);
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    margin-left: 18rem;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    padding-top: 5rem;
}
.backButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 45px;

    svg {
        width: 20px;
        height: 20px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        padding-left: 5px;
    }
}

.heading{
    display: grid;
    grid-template-columns: .01fr 1fr .5fr;
    width: 100%;
    gap: 50px;
    margin: 45px 0px 10px 0rem;
    .title{
        color: white;
        font-size: 22px;
        text-align: left;
    }
    .icons{
        display: flex;
        gap: 10px;
        svg{
            width: 32px;
            height: 32px;
            &:hover{
                cursor: pointer;
            }
        }
    }
}