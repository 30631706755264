@import "../../../../../assets/scss/_media-queries.scss";

.inputGroup {
  display: flex;
  flex-direction: column;
  label {
    color: var(--light-gray-v1);
    font-size: 14px;
  }
  .selectContainer {
    position: relative;
    width: 100%;
    padding: 0.8rem;
    border: 1px solid var(--light-gray-v3);
    color: var(--light-gray-v3);
    font-size: 12px;
    margin: 10px 0px;
    background: transparent;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .optionsList {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      border: 1px solid var(--light-gray-v3);
      border-top: none;
      border-radius: 0 0 9px 9px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 25.53%, rgba(36, 36, 36, 0.1) 100%), rgba(35, 35, 35, 0.9);
      color: var(--light-gray-v3);
      max-height: 150px;
      transform: scaleY(0);
      transition: all 0.2s ease-in-out;
      transform-origin: top;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      padding: 7px 0;
      .option {
        padding: 0.8rem;
        margin: 0;
        cursor: pointer;
        &:hover {
          background: var(--light-gray-v3);
          color: var(--dark-gray-v1);
        }
      }
      &.activeOptions {
        transform: scaleY(1) translateZ(100px);
      }

    }
  }
}

@include min(laptop) {
  .inputGroup {
    label {
      font-size: 16px;
    }
    .selectContainer {
      width: 100%;
      font-size: 14px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  margin: 2px 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 4px;
  height: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}