.row {
    border-bottom: .5px solid rgba(255, 255, 255, 0.2);
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr;
    padding: 0 10px;

    td {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.72);
        padding: 14px 0;
        align-self: center;

        .checkmark {
            border: 1px solid #A5A5A5;
            border-radius: 4px;
            background-color: transparent;
            width: 12px;
            height: 12px;
            display: block;
            margin-left: 15px;

            &:hover {
                background-color: #EC7600;
            }
        }

        span {
            display: block;
            svg {
                width: 10px;
                height: 10px;
                margin-left: 5px;
                transform: rotate(180deg);

                path {
                    fill: rgba(255, 255, 255, 0.72);
                }
            }
        }

        .active {
            color: white;
            svg {
                transform: rotate(0deg);
                path {
                    fill: white;
                }
            }
        }

        &:hover {
            color: var(--white);
            cursor: pointer;

            span {
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}
