@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/media-queries";

.container {
  @include flex-align(center, center);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;


  h1 {
    font-size: 22px !important;
    font-weight: 500
  }

  .heading {
    @include flex-align(center, space-between);
    margin: 15px 0px 15px 0rem;

    .title {
      @include flex-align(center, center);

      img {
        max-width: 60px;
        margin-right: 10px;
      }
    }

    .buttons {
      button {
        // padding: 8px 6px 4px 6px;
        max-width: 30px;
        cursor: pointer;
        margin: 0 4px;

        svg {
          width: 32px;
          height: 32px;

        }
      }

      .active {
        border-radius: 6px;

        svg {
          background: rgba(252, 253, 255, 0.08);
          border-radius: 50%;
        }
      }
    }
  }

  .content {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;

    .leftBtnGroup {
      display: flex;

      .searchInput {
        width: 310px
      }
    }

  }
}

body:global(.rightmenu-closed) {
  :local(.container) {
    padding-left: 164px;
    padding-right: 0;

    .content {
      padding-right: 0;
      width: calc(100% - 260px);
    }
  }
}

body:global(.universalmenu-closed) {
  :local(.container) {
    padding-right: 150px;
    padding-left: 0;

    .content {
      width: calc(100% - 300px);
    }
  }
}

body:global(.universalmenu-closed.rightmenu-closed) {
  :local(.container) {
    padding-right: 0;
    padding-left: 0;
  }
}

@include custom-max(1024) {
  .container {
    padding-right: 50px;
    padding-left: 90px;

    .content {
      width: 100%;
      padding-right: 0px;
    }
  }

  body:global(.universalmenu-closed) {
    :local(.container) {
      padding-left: 130px;
      padding-right: 45px;

      .content {
        width: 100%;
      }
    }
  }
}

@include max(tablet) {
  .container {
    .content {
      margin-left: 0;

      .title {
        h1 {
          font-size: 20px;
        }
      }
    }
  }
}

body:global(.universalmenu-closed) {
  :local(.container) {
    padding-left: 62px;
  }
}

body:global(.rightmenu-closed) {
  :local(.container) {
    padding-left: 305px;

    .content {
      width: calc(100% - 60px);
    }
  }
}

body:global(.rightmenu-closed.universalmenu-closed) {
  :local(.container) {
    padding-right: 0;
    padding-left: 0;

    .content {
      width: calc(100% - 145px);
    }
  }
}

body:global(.bright-mode) {
  .container {
    * {
      color: rgb(111, 110, 110);
    }

    .heading {
      svg {
        border: none;

        g,
        path {
          fill: rgba(54, 53, 53, 0.074);
          stroke-width: 0;
        }
      }
    }

    .active {
      svg {

        g,
        path {
          fill: rgba(77, 77, 77, 0.74);
        }
      }
    }
  }
}

@include custom-max(768) {
  .container {
    padding-top: 0.5rem;
  }

  .heading {
    align-items: unset !important;
    flex-direction: column;
    gap: 10px;

    .title {
      justify-content: left !important;
      h1 {
        font-size: 18px !important;
        font-weight: 600;
      }
    }
  }

  .content {
    .leftBtnGroup {
      .searchInput {
        width: unset !important;
      }
    }

  }
}

@include custom-max(334) {
  .container {
    padding-left: 50px;
  }
}
