@import "../../../../assets/scss/_media-queries.scss";
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  margin-top: 50px;
  gap: 50px;
  @include custom-max(1450) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
}

@include min(tablet) {
  body:global(.universalmenu-closed ) {
    :local(.container) {
      margin-left: -165px;
    }
  }
}

@include custom-max(1024) {
  body:global(.universalmenu-closed ) {
    :local(.container) {
      margin-left: 0px;
    }
  }
}