@import "../../../../../assets/scss/_media-queries.scss";

.inputGroup {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  label {
    color: var(--light-gray-v1);
    font-size: 14px;
    margin-left: 7px;
  }
  input {
    width: 75%;
    padding: 0.6rem 0.8rem;
    border: 1px solid rgba(128, 128, 128, 0.271);
    color: var(--light-gray-v3);
    font-size: 12px;
    margin: 10px 0px;
    background: transparent;
    border-radius: 30px;

    &::placeholder {
      color: var(--light-gray-v3);
    }
  }
}

@include min(laptop) {
  .inputGroup {
    label {
      font-size: 15px;
    }
    input {
      width: 94%;
      font-size: 14px;
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(50%);
}
