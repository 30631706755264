.dashboard {
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(25px);
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;

  .dashboardBox {
    width: 780px;
    height: 283px;
    background: #292929;
    border-radius: 20px;

    .topContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #333333;
      border-radius: 20px 20px 0px 0px;
      padding: 0px 42px;
      padding-right: 24px;
      height: 60px;

      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: -0.23px;
        color: #fcfdff;
      }

      .closeIcon {
        fill: #696d6e;
        cursor: pointer;
      }
    }

    .midContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: calc(100% - (78px + 60px));
      justify-content: center;
      padding: 0px 42px;

      h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: -0.23px;
        color: #fcfdff;
      }

      div {
        width: 695px;
        display: flex;
        input {
          flex: 1;
          color: #707070;
          background-color: #292929;
          border: 1px solid #464849;
          box-sizing: border-box;
          border-radius: 4px 0px 0px 4px;
          height: 33px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.23px;
          padding: 0 16px;
          border-right: none;
        }
        button {
          background: #333333;
          border: 1px solid #464849;
          box-sizing: border-box;
          border-radius: 0px 4px 4px 0px;
          width: 83px;
        }
      }
    }

    .bottomContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 78px;
      padding: 0px 42px;
      background: #333333;
      border-radius: 0px 0px 20px 20px;

      .link {
        h5 {
          font-weight: 600;
          font-size: 14px;
          letter-spacing: -0.23px;
          color: #fcfdff;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          letter-spacing: -0.23px;
          color: #fcfdff;
        }
      }

      button {
        padding: 10px 30px;
        border: white 1px solid;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.23px;
        border-radius: 5px;
      }
    }
  }
}
