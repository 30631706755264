@import "../../../../assets/scss/_media-queries.scss";
  
.getStarted{
    width: 85%;
    max-width: 700px;
    margin: 0 auto;
    padding-top: 70px;
    h1{
        font-size: 38px;
        color: white;
        span{
            color: var(--light-gray-v2);
        }
    }
    .location, .job{
        font-weight: bold;
    }
    .location{
        font-size: 25px;
        padding-top: 10px;
        padding-bottom: 30px;
    }
    .job{
        font-size: 30px;
        color: #FCFDFFD1;
        padding-top: 25px;
        padding-bottom: 18px;
    }
    .paragraph{
        padding: 25px 0px;
    }
    .team{
        padding-bottom: 25px;
    }
    a{
        color: var(--orange);
        font-size: 18px;
        text-decoration: underline;
    }
    p{
        line-height: 1.7em;
    }
    h4,p{
        color: var(--light-gray-v2);
    }
    img{
        width: 100%;
        max-width: 700px;
        height: 450px;
        border-radius: 10px;
    }
    @include max(tablet){
        h1{
            font-size: 35px;
        }
    }
}
