@import "../../../../assets/scss/_media-queries.scss";


.menuContainer {
  width: 226px;
  position: absolute;
  right: 0;
  color: var(--white);
  z-index: 9999;
  height: calc(100% - 50px);
  position: absolute;
  bottom: 0;
  transform: translateX(0);
  transition: .3s ease-in-out;
  background-color: #212121;
  #desc{
    margin-right: 10px;
  }
  &::after {
    position: absolute;
    top: 10%;
    height: 90%;
    z-index: -1;
    content: "";
    width: 105%;
    left: 0;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
  }
  .iconContainer{
    cursor: pointer;
    width: 22px;
    height: 22px;
    background-color: #414141;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    right: 1.3rem;
    margin-top: 20px;
    position: absolute;
    opacity: 0.7;
    &:hover{
      opacity: 1;
      background-color: #2c2c2c;
    }
    svg {
      transform: rotate(45deg);
    }
  }
  .imagePart {
    height:237px;
    width: 100%;
    min-height: 237px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
    background-position: bottom;
    background-image: url(../../../../assets/images/homemenu1.jpg) ;
    &::after{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.519);
      content: '';
    }
  }
  .mainBtns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    margin-top: 10px;
    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 5px;
      background-color: gray;
      cursor: pointer;
      &.activeBtn {
        background-color: var(--white);
      }
    }
  }
  .menuContent {
    margin-top: 12rem;
    padding: 0px 12px;
    padding-left: 18px;
    overflow-y: hidden;
    h2 {
      font-size: 24px;
    }
    &.bigHeading {
      margin-top: 16rem;
      h2 {
        font-size: 36px;
      }
    }
    h3 {
      color: var(--orange);
      font-size: 16px !important;
      margin-top: 2rem;
    }
    p {
      color: var(--light-gray-v1);
      font-size: 13px;
      line-height: 22px;
      margin: 10px 0px;
    }
    .menulist {
      display: flex;
      align-items: flex-start;
      margin: 0px 0px;
      margin-right: 14px !important;
      &.lineStyled {
        flex-direction: column;
        margin-top: 3rem;
        position: relative;
        z-index: 1;
        &::after {
          position: absolute;
          top: 30px;
          border-radius: 30px;
          left: 0;
          width: 100%;
          height: 3px;
          content: "";
          z-index: -1;
          background: rgb(236, 103, 0);
          background: linear-gradient(
            77deg,
            rgba(236, 103, 0, 1) 38%,
            rgba(0, 0, 0, 0) 94%
          );
        }
        p {
          margin-top: 2rem;
        }
      }
      .circle {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--orange);
        margin-top: 15px;
        border-radius: 50%;
        p {
          color: var(--white);
          margin-top: 8px;
        }
      }
    }
  }
}
@include min(laptop) {

  .menuContainer {
    width: 280px;
    .menuContent{
      margin-top: 17.5rem;
      padding-left: 30px;
      p{
        font-size: 14px;
        // margin-right: 5px;
      }
      h2{
        font-size: 28px;
      }
      h3{
        font-size: 18px !important;
      }
      .menulist{
        margin: 5px 0px;
      }
    }
    .mainBtns{
      button{
        width: 12px;
        height: 13px;
      }
    }
  }
}
.hideMenu{
  transform: translateX(100%);
  transition: .3s ease-in-out;
}

  .open{
    opacity: 1 ;
    cursor: pointer;
    z-index: 999 ;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 30px;
    top: 70px;
    opacity: 0.5;
    svg{
      width: 20px;
      height: 20px;
    }
    &:hover{
      opacity: 1;
    }
  }

  @media(max-height: 780px){
    .menuContainer{
      .menuContent{
        margin-top: 12rem;
        h2{
          font-size: 24px;
        }
        h3{
          font-size: 16px !important;
        }
        p{
          font-size: 13px;
        }
        .menulist{
          margin: 0 0;
        }
        .mainBtns{
          button{
          width: 10px;
          height: 10px;
          }
        }
      }
    }
  }

  @include max(laptop) {
    .menuContainer {
      width: 250px;
      .menuContent{
        h2{
          font-size: 25px;
        }
        p{
          font-size: 14px;
          margin-right: 10px;
        }
        h3{
          font-size: 17px;
        }
      }
    }
  }

  
  @include custom-max(1024) {
    .menuContainer, .open{
      display: none;
    }
  }
body:global(.purple-mode){
  .menuContainer{
    background-color: #191B26;
    &::after{
     display: none;
    }

    h3{
      color: white;
    }
    .circle{
      background-color: rgba(255, 255, 255, 0.375) !important;
    }
  }
}
body:global(.green-mode){
  .menuContainer{
    background-color: #121C19;
    &::after{
     display: none;
    }

    h3{
      color: white;
    }
    .circle{
      background-color: rgba(255, 255, 255, 0.375) !important;
    }
  }
}
body:global(.blue-mode){
  .menuContainer{
    background-color: #01253B;
    &::after{
     display: none;
    }

    h3{
      color: white;
    }
    .circle{
      background-color: rgba(255, 255, 255, 0.375) !important;
    }
  }
}
body:global(.bright-mode){
  .menuContainer{
    background-color: #d8d8d8;
    *{
      color: rgb(93, 93, 93);
    }
    &::after{
      display: none;
    }
  }
}

.menuContainer{
  .personalmenu{
    background-size: 190% 190%;
    background-position: top left;
    &::after{
      background-color: rgba(0, 0, 0, 0.354);
    }
  }
}
body:global(.dark-mode){
  .menuContainer{
    background-color: #1C1C1C;
    &::after{
      display: none;
    }
  }
}
.menuContainer{
  .meetingmenu{
    &::after{
      background-color: rgba(0, 0, 0, 0.24);
    }
  }
}
.menuContainer{
  .archivemenu{
    background-size: 150% 150%;
    background-position: top left;
  }
}
.menuContainer{
  .repomenu{
    background-size: 180% 150%;
    background-position: left center;
    background-position: top center;

    &::after{
      opacity: 0.7;
    }
  }
}
.menuContainer{
  .favmenu{
    background-size: 170% 130%;
    background-position: left center;
    &::after{
      opacity: 0.7;
    }
  }
}