.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 58vw;
  height: 80vh;
  background-color: white;
  padding: 2em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #1E1E1E;
  border-radius: 16px;

  .fileTitle {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10px;
  }

  :global {
    .pg-viewer-wrapper {
      height: 100%!important;
      width: 100%!important;
      .photo-viewer-container {
        width: 100%!important;
        height: 100%!important;

        img {
          width: 100%!important;
          height: 100%!important;
          object-fit: contain!important;
        }
      }
    }
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.closeButton {
  position: absolute;
  top: 1em;
  right: 1em;
  padding-bottom: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 20;
}
