@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/colors";
@import "../../../../assets/scss/media-queries";

.wrapper {
  @include flex-align(center, center);
  padding-bottom: 50px;
  margin-right: 130px;
  .videoHolder {
    @include flex-align(center, center);
    @include flex-direction(column);
    width: 100%;
    max-width: 50%;
    margin-top: -50px;
    .videoContainer {
      width: 80%;
      background-color: #151515;
      padding: 12px 12px;
      border-radius: 10px;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 25px;
    }
    h2 {
      text-align: center;
      font-size: 33px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 15px;
      color: var(--white);
    }
    p {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
      color: var(--light-gray-v2);
      max-width: 670px;
      span {
        text-decoration: underline;
      }
    }
  }
  .listHolder {
    width: 100%;
    max-width: 19%;
    h2 {
      font-size: 20px;
      line-height: 35px;
      font-weight: bold;
      margin-bottom: 15px;
      color: var(--white);
    }
    h3,p{
      color: var(--light-gray-v2);
    }
    p {
      font-size: 16px;
      line-height: 26px;
      font-weight: normal;
    }
    ul {
      margin-top: 30px;
      margin-bottom: 50px;
      list-style: none;
      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 30px;
        color: #c1c1c1;
        cursor: pointer;
        transition: 0.1s ease-in-out;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          height: 100%;
          background-image: url(../../../../assets/images/grayVideoButton.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 80%;
          height: 1px;
          background-color: #4d4d4d;
        }

        &:first-child {
          color: #ec7600;
          &::before {
            background-image: url(../../../../assets/images/videoButton.svg);
          }
          &:hover {
            color: #ec7600;
            font-weight: normal;
          }
        }
        &:hover {
          font-weight: bold;
          color: var(--white);
          transition: 0.1s ease-in-out;
        }
      }
    }
  }
  .unorderedList {
    h2 {
      font-size: 28px;
      line-height: 35px;
      font-weight: 600;
      margin-bottom: 40px;
      color: #c1c1c1;
    }
    h3 {
      margin-bottom: 40px;
      font-size: 22px;
      font-weight: normal;
      line-height: 26px;
      color: var(--light-gray-v2);
    }
    ul {
      list-style: disc;
      padding-left: 25px;
      color: var(--light-gray-v2);
      li {
        padding-left: 0;
        font-size: 16px;
        line-height: 26px;
        font-weight: normal;
        color: var(--light-gray-v2);
        margin-bottom: 5px;
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
        &:first-child {
          color: var(--light-gray-v2);
        }
      }
    }
    .buttons {
      margin-top: 70px;
      display: flex;
      span {
        transform: translateY(10px);
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  @include max(laptop) {
    .videoHolder{
      max-width: 95%!important;
    }
    .listHolder{
      max-width:515px!important;
      padding-top: 20px;
    }
    .unorderedList .buttons{
      margin-top: 50px;
    }
  }
  @include max(tablet) {
    &.withoutBanner1{
      padding-top: 80px;
      .listHolder{
        max-width: 300px!important;
      }
    }
  }
}

.withoutBanner {
  padding-top: 50px;
  .videoHolder {
    margin-top: 0;
  }
  .listHolder {
    margin-top: -120px;
  }
}

.withoutBanner1 {
  padding-top: 50px;
  margin-right: 0;
  justify-content: flex-start;
  .videoHolder {
    margin-top: 0;
    max-width: 64%;
  }
  .listHolder {
    max-width: 20%;
    margin-top: -47px;
  }
  @include max(laptop) {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
  }
}

@media (max-width: 1299px) {
  .wrapper {
    margin-right: 0;
    .videoHolder {
      max-width: 70%;
      h2 {
        font-size: 25px;
        line-height: 35px;
      }
      p {
        max-width: 500px;
      }
    }
    .listHolder {
      max-width: 35%;
    }
  }
}

@media (max-width: 991px) {
  .wrapper {
    .videoHolder {
      margin-top: -65px;
      padding-left: 10px;
      padding-right: 10px;
      h2 {
        font-size: 20px;
        padding-right: 20px;
      }
      p {
        max-width: 400px;
        padding-right: 20px;
        padding-left: 10px;
      }
    }
    .listHolder {
      padding-top: 17px;
      max-width: 29%;
      margin-bottom: 20px;
      padding-right: 50px;
      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      ul {
        margin-bottom: 30px;
        li {
          &:after {
            width: 100%;
          }
        }
      }
    }
    .unorderedList {
      .buttons {
        margin-top: 0;
        @include flex-direction(column);
        gap:10px;
        span {
          display: none;
        }
      }
      h3 {
        font-size: 18px;
        max-width: 250px;
        margin-bottom: 20px;
      }
    }
  }
  .withoutBanner {
    .videoHolder {
      margin-top: 0;
    }
    .listHolder {
      padding-top: 0;
      margin-top: 0;
    }
  }
}
