.carouseWraper {
  margin-top: 70px !important;
  max-width: 1600px;
  color: #FCFDFF;
  margin: 0 auto;
  .arrowButtons {
    width: 25px;
    height: 25px;
    margin-top: 58px;
    font-size: 30px;
    color: #C1C1C1;
  }
}
