@import "../../../../../assets/scss/mixins";
.containerRecordingTabs {
  position: absolute;
  bottom: 66px;
  z-index: 1;

  .recordingTabs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background: #0e0e0e;
    border: 1px solid #818487;
    box-sizing: border-box;
    border-radius: 20px;
    height: 93px;
    width: 489px;
    
    >div, button {
      @include flex-direction(column);
      @include flex-align(center, center);
      svg {
        margin-bottom: 15px;
      }
      cursor: pointer;
      &:first-child {
        border-right: 1px solid #818487;
        span {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          color: #c1c1c1;
        }
      }
    }
    .audio {
      position: relative;
      .pidWrapper {
        width: 60px;
        height: 7px;
        background: #454545;
        border-radius: 100px 100px 0px 0px;
        position: absolute;
        bottom: 0;
        .pid {
          width: 0%;
          height: 6px;
          background: #ec7600;
          border-radius: 100px 0px 0px 0px;
        }
      }
    }
  }
}
