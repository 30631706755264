@import "../../../../assets/scss/media-queries";

.cardContainer {
  padding: 30px 20px;
  max-width: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  background-color: #1b1b1b;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  h3 {
    text-align: center;
    font-size: 19px;
    line-height: 20px;
    color: #696d6e;
    font-weight: bold;
    margin-bottom: 15px;
  }
  h2 {
    text-align: center;
    font-size: 30px;
    color: #ffffff;
  }
  &.active {
    background-color: #292929;
    h3 {
      text-align: center;
      color: #ec7600;
    }
    h2 {
      text-align: center;
      color: #ec7600;
    }
  }
}

@media (max-width: 1199px) {
  .cardContainer {
    padding: 20px 10px;
    h3 {
      font-size: 16px;
    }
    h2 {
      font-size: 20px;
    }
  }
}

@media (max-width: 991px) {
  .cardContainer {
    padding: 20px 28px;
  }
}
