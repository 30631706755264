@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/_media-queries.scss";

.myVideo {
    padding-top: 50px;
    margin: 0 auto;
    width: 91%;
    max-width: 1510px;
    h1 {
        font-size: 42px;
        color: var(--white);

    }

    span {
        color: #44484a;
    }

    .paragraphLink {
        @include flex-align(center, flex-start);
        padding-top: 15px;
        padding-bottom: 25px;
        width: 100%;
        color: var(--light-gray-v2);
        font-size: 25px;

        a {
            text-decoration: underline;
        }

        span {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .videoQuestion {
        @include flex-align(flex-end, flex-start);
        gap: 60px;

        .videoWrapper {
            width: 50%;
            max-height: 452px;
        }

        img {
            width: 680px;
        }

        h1 {
            color: #FCFDFFD1;
            font-size: 30px;
            padding-bottom: 35px;
        }

        p {
            color: var(--light-gray-v2);
            font-size: 22px;
            line-height: 1.5em;
            max-width: 340px;
        }

        .buttons {
            display: flex;
            gap: 20px;
            padding-top: 65px;
        }
    }

    @include max(laptop) {
        .videoQuestion{
            flex-wrap: wrap;
            p {
                max-width: 900px;
            }
            .videoWrapper {
                width: 100%;
                max-height: max-content;
            }
        }
    }

    @include custom-max(1440) {
        width: 91%;
    }
    @include custom-min(1510) {
        width: 95%;
    }
    @include custom-max(1300) {
        width: 93%;
    }
    @include custom-max(1024) {
        width: 90%;
    }
}