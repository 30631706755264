
@import "../../../../assets/scss/_media-queries.scss";

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 1rem;
}

@include min(laptop) {
  .gridContainer {
    justify-content: center;
  }
}

@include max(tablet) {

  body:global(.universalmenu-closed) {
    :local(.gridContainer) {
      padding-left: 0;
    }
  }
  body:global(.rightmenu-closed) {
    :local(.gridContainer) {
      padding-right: 0;
    }
  }
  body:global(.universalmenu-closed.rightmenu-closed){
    :local(.gridContainer){
      padding-right: 0;
      padding-left: 0;
    }
  }

}

