.settingsMain {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  font-family: 'Raleway', Arial;
  .auxiliaryBackground {
    height: 90px;
    width: 100%;
    background-color:#214940;
  }
  .mainDashboard {
    position: relative;
    min-height: calc(100% - 90px);
    width: 100%;
    background-color:#151515;
  }

  * {
    box-sizing: border-box;
  }
}