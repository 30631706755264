.chart {
  padding-right: 50px;
  margin-left: 357px;
}
.wrapper {
  margin-top: 70px;
  max-width: 93%;
  width: 100%;
  overflow: hidden;
  color: #ec76001e;
  background-color: #1b1b1b;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
}
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 70px;
  flex-wrap: wrap;
}
.text {
  padding-right: 30px;
}
.text h3 {
  padding-left: 20px;
  color: #c1c1c1;
}
.text p {
  padding-left: 20px;
  color: #c1c1c1;
}
.list {
  display: flex;
}
.list p {
  position: relative;
  color: #c1c1c1;
  padding-right: 50px;
}
.list p:last-child {
  padding-right: 0;
}
.list p::before {
  position: absolute;
  content: "";
  top: 10px;
  left: -25px;
  width: 18px;
  height: 2px;
  background-color: #c1c1c1;
}
.list p:first-child::before {
  background-color: #ec7600;
}

@media (max-width: 1199px) {
  .chart {
    padding-right: 80px;
  }
}
@media (max-width: 1099px) {
  .chart {
    margin-left: 295px;
    padding-right: 35px;
  }
}

@media (max-width: 991px) {
  body:global(.universalmenu-closed) {
    :local(.chart) {
      margin-left: 65px !important;
    }
  }
}

body:global(.universalmenu-closed) {
  :local(.chart) {
    margin-left: 135px;
  }
}
