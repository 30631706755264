@import "../../../../../assets/scss/mixins";
.contentModal {
  box-sizing: border-box;
  max-width: 516px;
  background: linear-gradient(0deg, rgba(60, 60, 61, 0.5), rgba(60, 60, 61, 0.5)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 25.53%, rgba(36, 36, 36, 0.2) 100%);
  border: 1px solid rgba(151, 151, 151, 0.2);
  backdrop-filter: blur(70px);
  border-radius: 25px;
  @include flex-direction(column);
  .content {
    padding: 41px 77px;
  }
  .banner {
    height: 50px;
    width: 100%;
    background-image: url(../../../../../assets/images/modalbanner.png);
    background-position: center;
    background-size: cover;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding: 5px 0;
    h2 {
      font-family: "Raleway";
      font-weight: lighter;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
      margin: 12px 0 0 20px;
    }
  }
  &:hover {
    cursor: default;
  }
  span {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.23px;
    color: #c1c1c1;
    margin-bottom: 34px;
    max-width: 362;
  }
  .settings {
    width: 100%;
    @include flex-direction(column);

    .settingsTitle {
      font-family: "Raleway";
      font-weight: lighter;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.23px;
      color: #ffffff;
      margin: 20px 0 10px 0;
    }

    > div {
      position: relative;
      & *:hover {
        cursor: pointer;
      }

      svg {
        position: absolute;
        width: 10px;
        left: 8px;
        top: 4.5px;
      }
      span,
      select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 17px;
        position: static;
        width: 100%;
        border-radius: 4px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        border: 1px solid #a7acb1;
        box-sizing: border-box;
        margin-bottom: 16px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        background-color: transparent;
        text-indent: 5px;
        option {
          font-size: 16px;
          background-color: #272728;
          line-height: 24px;
          padding: 5px;
        }
      }
    }
    .select {
      margin-bottom: 15px;
      width: 100%;
      &.select1 {
        margin-top: -15px;
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: rgb(54, 54, 54);
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 5px rgb(118, 118, 118);
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #ec76003f;
        }
      }
      svg {
        top: 9px;
        width: 17px;
        display: flex;
        margin-left: 325px;
        top: 16px;
      }
      .title {
        width: 100%;
        border: 1px solid #777777a1;
        padding: 12px 12px;
        display: flex;
        border-radius: 6px;
        max-width: 343px;
        svg {
          margin-left: 8px;
          width: 20px;
          top: 0px;
        }
        p {
          padding-left: 40px;
          padding-right: 5px;
          margin-bottom: 0;
          color: #ffffff;
          font-weight: lighter;
          font-size: 14px;
          margin-bottom: 0;
        }
      }

      .dropdown {
        position: absolute;
        z-index: 99999;
        padding-top: 5px;
        width: 100%;
        height: auto;
        overflow-y: auto;
        background-color: #212121 !important;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        .wrapper {
          display: flex;
          flex-direction: row;
        }
        .radio {
          padding-top: 10px;
          display: flex;
          .input {
            padding-bottom: 10px;
            padding-left: 20px;
            min-width: 90px;
            label {
              padding-left: 10px;
              font-weight: lighter;
              font-size: 14px;
            }
          }
        }
        h3 {
          font-size: 16px;
          color: rgb(202, 202, 202);
          padding-left: 20px;
          padding-top: 10px;
          font-weight: 600;
          padding-bottom: 5px;
        }
        p {
          font-size: 14px;
          font-weight: lighter;
          padding-left: 20px;
          margin-bottom: 0;
          padding-top: 5px;
          padding-bottom: 7px;
          &:hover {
            background-color: #3e3e3e;
          }
        }
        input[type="radio"] {
          appearance: none;
          border: 1px solid #d3d3d3;
          width: 14px;
          height: 14px;
          content: none;
          outline: none;
          margin: 0;
          border-radius: 2px;
        }

        input[type="radio"]:checked {
          appearance: none;
          outline: none;
          padding: 0;
          content: none;
          border: none;
        }

        input[type="radio"]:checked::before {
          position: absolute;
          color: #ffffff !important;
          content: "\00A0\2713\00A0" !important;
          // border: 1px solid #d3d3d3;
          font-weight: bolder;
          font-size: 10px;
          padding-left: 2px;
          width: 14px;
          height: 14px;
          background-color: #ec7600;
        }
        &.dropdown1 {
          height: 235px;
        }
      }
      &.second {
        .title {
          svg {
            width: 10px;
            top: 7px;
            left: 12px;
          }
        }
      }
      &.third {
        .title {
          svg {
            top: 9px;
          }
        }
      }
      &.fourth {
        .title {
          svg {
            top: -3px;
            width: 15px;
          }
        }
      }
    }
    .camera {
      svg {
        width: 13px;
        top: 6.5px;
        left: 5px;
      }
    }
  }
  button {
    @include flex-direction(row);
    @include flex-align(center, center);
    background: #ef8d2b;
    border-radius: 30px;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0px 0px;
    height: 44px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.23px;
    text-transform: capitalize;
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
  }
}
@media (max-height: 855px) {
  .contentModal {
    .settings {
      .select {
        .dropdown {
          &.dropdown1 {
            height: 155px;
          }
        }
      }
    }
  }
}

@media (max-height: 700px) {
  .contentModal {
    .settings {
      .select {
        .dropdown {
          &.dropdown1 {
            height: 117px;
          }
        }
      }
    }
  }
}

@media (max-height: 600px) {
  .contentModal {
    .settings {
      .select {
        .dropdown {
          &.dropdown1 {
            height: 80px;
          }
        }
      }
    }
  }
}

.feedback {
  color: #ef8d2b;
  text-align: center;
}

.permissionInstructions {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}
