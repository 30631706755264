button {
  cursor: pointer;
  .searchIcon {
    position: absolute;
    z-index: 5;
    color: #8e9294;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
  }
}
