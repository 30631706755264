.dropdown {
  position: absolute;
  visibility: hidden;
  min-width: 170px;
  z-index: 99;
  right: 5px;
  bottom: -218px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  background-color: #272727;
  border-radius: 10px;
  &.showDropdown {
    visibility: visible;
}
  button {
    cursor: pointer;
    padding: 7px 20px;
    font-size: 17px;
    text-align: left;
  }
  .buttonHover {
    width: 100%;
    &:hover {
      background-color: rgba(255, 255, 255, 0.144)!important;
    }
  }
}

.dropDownUp {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
  right: -3rem;
  bottom: 0.7rem;
  border-radius: 4px;
  overflow: hidden;

  button {
      cursor: pointer;
      padding: 7px 20px;
      font-size: 17px;
      text-align: left;
  }
}