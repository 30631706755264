@import "../../../../assets/scss/media-queries";
.row {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    display: grid;
    grid-template-columns: 2fr 1.3fr 2fr 1.3fr 1fr 1fr;
    padding: 15px 0px;
    th {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFFB8;
        align-self: center;
        display: inline-flex;
        flex-direction: column;
        gap: 8px;
        span {
            display: inline-flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            svg {
                width: 14px;
                height: 13px;

                path {
                    fill: #9DA3A4;
                }
            }
        }
        select {
            background-color: transparent;
            border: none;
            color: #FFFFFFB8;
            width: min-content;
            outline: none;
            option {
                background-color: #272727;
                border: none;
                margin: 30px;
                border-radius: 5px;
                &:hover {
                    background: #FFFFFFB8;
                }
            }
        }
        .dropdown {
            background-color: #272727;
            border-radius: 5px;
            width: 155px;
            visibility: hidden;
            margin-top: 30px;
            position: absolute;
            .buttonDropdown {
                cursor: pointer;
                padding: 10px;
                font-size: 14px;
                text-align: left;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.144);
                }
            }
        }
        .showDropdown {
            visibility: visible;
        }
        &:hover {
            color: white;

            svg path {
                fill: white;
            }
        }
    }
    @include custom-max(1300) {
        grid-template-columns: 1.4fr 1.1fr 1.7fr 0.8fr 1.5fr 0.5fr;
    }
}
