@import "../../../../assets/scss/_media-queries.scss";

.videoCard {
  margin: 20px 15px;
  width: 220px;
  position: relative;
  .imagePart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
    // min-height: 135px;
    background-position: center;
    background-size: 124%;
    border-radius: 0.5rem;
    position: relative;
    img {
      border-radius: 5px;
      width: 100%;
      height: 150px;
    }
    .bottom {
      position: absolute;
      bottom: 10px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 85%;
      svg {
        transform: scale(1.4);
        opacity: 0.8;
        padding: 5px 10px;
      }
      p {
        padding: 5px 10px;
        padding-bottom: 8px;
        background-color: rgba(51, 51, 51, 0.473);
        font-size: 14px;
        color: var(--white);
      }
    }
  }
  .heading {
    display: flex;
    justify-content: space-between;
    margin: 5px 29px;
    span {
      cursor: pointer;
    }
    div {
      width: 5px;
      height: 5px;
      background-color: var(--light-gray-v3);
      margin: 2px;
      border-radius: 50%;
      cursor: pointer;
    }
    svg {
      cursor: pointer;
    }
    h2 {
      font-weight: 500;
      font-size: 14px;
      margin-top: 7px;
      margin-bottom: 2px;
    }
  }
  p {
    color: var(--light-gray-v3);
    margin-left: 3px;
    font-size: 12px;
  }
}
@include min(laptop) {
  .videoCard {
    width: 220px;
    .imagePart {
      background-size: 120%;
    }
    .heading {
      h2 {
        font-size: 18px;
      }
    }
    p {
      font-size: 16px;
    }
  }
}
