.carouselWraper {
  display: flex;
  flex-direction: column;
  max-width: 279px;
  .img {
    position: relative;
    img {
      width: 100%;
      height: 157px;
    }
    span {
      position: absolute;
      bottom: 15px;
      z-index: 1;
      font-family: "Roboto";
      right: 15px;
      background: rgba(38, 38, 38, 0.65);
      backdrop-filter: blur(50px);
      width: 30px;
      height: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 2px;
      align-items: center;
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      text-align: right;
      letter-spacing: 0.12px;
      color: rgba(252, 253, 255, 0.82);
    }
  }

  .desc {
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 20px;
    }
    p {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.23px;
      max-width: 263px;
      color: #c1c1c1;
    }
  }
}
