@import "../../../../assets/scss/_mixins.scss";
.icons{
    @include flex-align(center, flex-start);
    gap: 15px;
    margin-left: 2rem;
    .iconBg{
        background: #333333;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        @include flex-align(center, center);
        transition: 1s ease;
        cursor: pointer;
        svg{
            width: 18px;
            height: 18px;
        }
        &:hover{
            background: #191919;
            border-radius: 30%;
        }
    }
}