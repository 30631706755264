.sharedfolder {
  width: 304px;
  height: 32px;
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  padding-top: 30px;
  padding-left: 10px;

  .image {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(66, 66, 66);
    border-radius: 4px;
  }

  .information {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dots {
      width: 30%;
      gap: 5px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-right: 5px;
    }

    &:hover {
      .dots {
        display: grid;
      }
    }

    .block {
      width: 70%;
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.23px;
        color: #c1c1c1;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.12px;
        color: #8e9294;
      }
    }
  }
}

.sharedfolder .dots svg {
  display: none;
}

.sharedfolder:hover .dots svg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  cursor: pointer;
}
