* {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  &:focus {
    outline: none;
  }
}
html,
body {
  overflow-x: hidden;
}
body {
  background-color: var(--dark-gray);
}
a {
  text-decoration: none;
  color: white;
}
.uni-menu-wrapper {
  width: 300px !important;
  position: fixed;
  z-index: 2!important;
  .uni-menu-size {
    height: calc(100% - 49.5px);
  }
  .uni-menu-footer {
    position: relative;
    margin-top: auto;
  }
}
button {
  border: none;
  background: none;
  color: white;
  cursor: pointer;
}
ul {
  list-style: none;
}
