.backButton {
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        padding-left: 5px;
    }
}
