.FixedMainContainer {
  box-sizing: border-box;
  position: fixed;
  top: 90px;
  bottom: 10px;
  left: 280px;
  right: 30px;
  padding: 35px 30px 20px 10px ;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin: 100px 0;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: rgba(35, 35, 35, 0.8);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(35, 35, 35, 0.9);
  }
}