.cards {
  display: flex;
  padding-left: 355px;
  padding-right: 115px;
  flex-wrap: wrap;
  row-gap: 35px;
}

body:global(.universalmenu-closed) {
  :local(.cards) {
    padding-left: 130px;
  }
}
@media (max-width: 1099px) {
  .cards {
    padding-left: 295px;
  }
}
@media (max-width: 991px) {
  body:global(.universalmenu-closed) {
    :local(.cards) {
      padding-left: 70px;
    }
  }
}
