@import "../../../../assets/scss/_media-queries.scss";

.main {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 3rem;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  min-height: 32vh;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  z-index: 1;

  &:after {
    content: "";
    mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: rgba(13, 13, 13, 0.6);
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 20px;
  }

  .content {
    padding-left: 25px;
    padding-right: 55px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 15px;

    h2 {
      font: 900 20px/30px Raleway;
      color: var(--white);
      margin-bottom: 5px;
    }

    p {
      font: 500 17px/23px Raleway;
      color: var(--white);
    }

    .btnWrapper {
      padding-top: 10px;
      display: flex;
      justify-content: flex-start;

      button {
        padding-left: 0;

        &:hover {
          svg {

            g,
            path {
              fill: white;
            }
          }
        }
      }

      svg {
        margin-right: 5px;
      }
    }
  }
}

@include min(laptop) {
  .banner {
    .content {
      padding-left: 90px;
      h2 {
        font: 900 40px/45px Raleway;
      }
    }
  }
}

body:global(.universalmenu-closed) {
  :local(.main) {
    .container {
      padding-left: 130px;
    }
  }
}

body:global(.rightmenu-closed) {
  :local(.main) {
    .container {
      padding-right: 118px;
    }
  }
}

@include custom-max(1024) {
  .container {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@include max(tablet) {
  .main {
    .container {
      padding-right: 50px;
      padding-left: 50px;
    }
  }

  body:global(.universalmenu-closed.rightmenu-closed) {
    :local(.main) {
      .container {
        padding-left: 62px;
      }
    }
  }

  body:global(.universalmenu-closed) {
    :local(.main) {
      .container {
        // padding-right: 245px;
        padding-left: 62px;
      }
    }
  }
}


.interview {
  padding-right: 115px;
  padding-left: 355px;
}

.interviewBanner {
  background-image: url(../../../../assets/images/videointerview.jpg);
}

.profilebanner {
  background-image: url(../../../../assets/images/cover1.webp);
  padding: 7rem 5rem;
  background-size: cover;
  width: 70%;
  margin-left: -4rem;

  &::after {
    background-color: rgba(0, 0, 0, 0.404);
  }
}

.recordings {
  margin-right: 0;

  .recordingBanner {
    padding-top: 40px;
    background-position: cover;
    background-position: center;
    padding-bottom: 40px;

    &::after {
      background-color: #0d0d0d36;
    }

    button {
      background-color: #EF8D2B;
      font-weight: bold;
    }
  }

  .content {
    p {
      margin-bottom: 20px !important;
    }
  }
}

@media (max-width: 1099px) {
  .interview {
    padding-right: 45px;
    padding-left: 290px;
  }
}

@media (max-width: 991px) {
  .interview {
    padding-right: 50px;
  }
}