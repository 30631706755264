@import "../../../../assets/scss/_media-queries.scss";
.container {
  width: calc(100% + 240px);
  min-height: 100vh;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  //justify-content: center;
  box-sizing: border-box;
  //position: relative;

  .center {
    width: 64%;
    display: flex;
    @include custom-max(1024){
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 20px;
    }
  }
}
@include custom-max(1024){
  body:global(.universalmenu-closed) {
    :local(.container) {
      padding-left: 160px;
    }
  }
}
@include max(tablet){
  .container{
    .center{
      width: 60%;
      justify-content: center;
      gap: 80px;
    }
  }
}