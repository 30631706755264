@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/media-queries";

.container {
    @include flex-align(center, center);
    flex-direction: column;
    padding-right: 115px;
    padding-left: 355px;
    padding-top: 1rem;
    .titleSearch{
        @include flex-align(center, center);
        gap:20px;
    }
    .heading {
        @include flex-align(center, space-between);
        margin: 15px 0 15px 0;

        .title {
            @include flex-align(center, center);
            h1{
                font-weight: 600;
                font-size: 20px;
            }
        }

        .buttons {
            button {
                padding: 8px 6px 4px 6px;
                cursor: pointer;
                svg {
                    object-fit: contain;
                }
            }

            .active {
                background: rgba(252, 253, 255, 0.08);
                border-radius: 6px;
            }
        }
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
}


@include max(tablet){
  body:global(.universalmenu-closed){
    :local(.container){
      padding-left:60px!important;
    }
  }
}

@media (max-width: 1099px) {
  .container {
    padding-left: 290px;
    padding-right: 45px;
  }
}
body:global(.universalmenu-closed){
  :local(.container){
    padding-left: 130px; 
  }
}