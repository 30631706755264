.saveFileModal {
  width: 627px;
  height: 270px;
  .header {
    display: flex;
    justify-content: space-between;
    background: #333333;
    border-radius: 20px 20px 0px 0px;
    height: 60px;
    .closeBtn {
      padding-right: 25px;
      font-size: 19px;
    }
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
      padding: 12px 42px;
    }
  }
  .content {
    background: #292929;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 39px;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 50px;
    padding: 50px 25px;

    a, button {
      width: 154px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-left: 12px;
      cursor: pointer;
    }
    :nth-child(1) {
      background: rgba(61, 61, 61, 0.5);
      border: 1px solid rgba(136, 139, 140, 0.12);
      box-sizing: border-box;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.23px;
      text-transform: capitalize;
    }
    :nth-child(2) {
      background: #ec7600;
      border: 1px solid rgba(136, 139, 140, 0.12);
      box-sizing: border-box;
      border-radius: 6px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.23px;
      text-transform: capitalize;
      color: #ffffff;
    }

    .uploadLoading {
      background: rgba(136, 139, 140, 0.12) !important;
      cursor: not-allowed;
      div {
        background: transparent;
        border: none;
        height: inherit;
        align-items: center;
        svg {
          height: 80%;
          background: transparent;
          border: none;
        }
      }
    }
  }
}
