@import "../../../../assets/scss/_media-queries.scss";

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
  margin-top: 50px;
  gap: 10px;
  @include custom-max(1450) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding-bottom: 60px;
  }
  margin-left: 3%;
  margin-top: 30px;
}

@include min(tablet) {
  body:global(.universalmenu-closed ) {
    :local(.container) {
      margin-left: -165px;
    }
  }
}
@include custom-max(1024) {
  body:global(.universalmenu-closed ) {
    :local(.container) {
      margin-left: 0px;
    }
  }
}