.mainContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 600px;
  min-width: 220px;
  .elementContainer {
    padding: 25px 170px 25px 0;
    border-bottom: 1px solid gray;
    margin-right: -170px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.23px;
      text-transform: uppercase;
      color: #8E9294;
    }
    p {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.23px;
      color: rgba(252, 253, 255, 0.82);
      margin-bottom: 10px;
    }
    div input {
      width: 100%;
    }
    &:last-child {
      border-bottom: none;
    }
    &.contacts {
        :nth-child(3),
        :nth-child(4) {
          input {
            padding-left: 50px;
            /* background here */
          }
      }
    }
  }
}
