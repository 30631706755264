@import "../../../../../assets/scss/mixins";

.popupRecording {
  @include flex-direction(column);
  @include flex-align(center, center);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #00000069;
  backdrop-filter: blur(18px);
  cursor: pointer;
}