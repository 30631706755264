@import "../../../../../assets/scss/_mixins.scss";
@import "../../../../../assets/scss/_media-queries.scss";

.container {
  display: flex;
  padding: 1rem 0;
  flex-direction: row-reverse;
  gap: 10%; /* Use percentage for better responsiveness */
  background-image: url(../../../../../assets/images/homeBack.png);
  background-position: center;
  background-size: cover;

  img {
    max-width: 22rem;
    width: 100%;
  }

  h1 {
    margin-top: 6rem;
    opacity: 0.95;
    font-size: 4vw; /* Use viewport width for responsive font size */
    text-align: left;
    font-weight: bold;
    background: linear-gradient(
      92deg,
      #fff 0%,
      #adadad 45.83%,
      #fff 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 1.4vw; /* Use viewport width for responsive font size */
    color: var(--light-gray-v2);
    margin-top: 1rem;
    max-width: 20rem;
    text-align: center;
    margin-bottom: -2rem;
    span {
      font-weight: bold;
    }
  }

  @include custom-max(1024) {
    padding-left: 2%; /* Use percentage for padding */
    img {
      max-width: 36% !important;
    }
  }

  @include min(laptop) {
    padding-top: 3vw; /* Use viewport width for padding */
    img {
      max-width: 36%;
    }
    p,
    h1 {
      max-width: max-content;
    }
  }

  @include custom-max(1300) {
    img {
      max-width: 35%;
    }
  }

  @include custom-max(1150) {
    img {
      max-width: 38%;
    }
  }

  @include max(laptop) {
    margin-right: 0rem;
  }

  body:global(.bright-mode) {
    * {
      color: rgb(99, 97, 97);
    }
  }
}
