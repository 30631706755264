@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/_media-queries.scss";
.container {
  @include flex-align(center, start);
  min-height: 94vh;
  padding-right: 280px;
  padding-left: 250px;
  .content {
    @include flex-align(center, center);
    flex-direction: column;
    width: 100%;
    img {
      max-width: 70rem;
      width: 100%;
      border-radius: 0.7rem;
    }
    .tooltip {
      position: absolute;
      padding: 0.6rem 1.2rem;
      border-radius: 10px;
      background-color: rgb(44, 44, 44);
      margin-top: -2.5rem;
      margin-left: -3.2rem;
      font-size: 0.9rem;
      visibility: hidden;
      white-space: nowrap;
    }
    .fullScreen {
      &:hover {
        .tooltip {
          visibility: visible;
        }
      }
    }
    .slidesShow {
      &:hover {
        .tooltip {
          visibility: visible;
        }
      }
    }
    .slides {
      @include flex-align(center, center);

      .arrow {
        width: 50px;
        margin: 0 0.5rem;
        height: 35px;
        cursor: pointer;
        // padding: 20px;
        @include flex-align(center, center);
        svg {
          circle {
            fill: var(--dark-gray-v1);
          }
          &:hover {
            circle {
              fill: #434343;
            }
          }
          g,
          path {
            fill: white;
          }
        }
      }
    }
    .bottom {
      width: 87%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-top: -1.4rem;
      .pages {
        h3 {
          font-size: 14px;
          color: var(--light-gray-v2);
        }
      }
      svg {
        width: 24px;
        height: 24px;
        // margin: 5px;
        cursor: pointer;
        g,
        path {
          fill: rgb(148, 148, 148);
        }
        &:hover {
          g,
          path {
            fill: white;
          }
        }
      }
    }
    .modeChanging{
      z-index: 1;
      opacity: 0;
      width: 500px;
      position: absolute;
      width: 500px;
      top: 30px;
      right: 30px;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      background-color: #232323;
      border-top: 2px solid #535353;
      h3{
        padding-bottom: 12px;
      }
      p{
        font-size: 14px;
      }
      svg{
        fill: #6f6f6f;
      }
      @include custom-max(1000){
        opacity: 1;
      }
    }
  }
}
.fullScreenMode {
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: relative;
  .content {
    width: 100%;
    height: 100%;
    .bottom {
      position: absolute;
      bottom: 20px;
      align-items: flex-end;
      justify-content: space-evenly;
      height: 255px!important;
      width:100%;
      .iconsRight{
        @include flex-align(center, center);
        gap: 10px;
      }
      svg{
        background-color: #33333399;
        border-radius: 50%;
      }
      .pages{
        h3{
          padding: 4px 10px;
          background-color: #33333399;
          border-radius: 15px;
        }
      }
      .slider{
        width: 88%;
         .isHovered {
            display: none;
        }
        .carouseli{
          opacity: 0;
          width: 88%;
          position: absolute;  
          bottom: -3rem;
          transition: 0.3s ease-in-out;
          .imgWrapper{
            height: 90px;
            width: 160px;
            img{
              height: 95%;
              width: 98%;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .imgStyle{
              border: 2px solid #838383;
            }
            .activeImage{
              border: 2px solid orange;
            }
          }
        }
        @include max(laptop) {
            width: 77%;
            .carouseli{
              width: 80%;
            }
        }
        @include max(tablet) {
          width: 70%;
          .carouseli{
            width: 70%;
          }
        }
        @include custom-min-max(1200, 1500) {
          width: 83%;
          .carouseli{
            width: 84%;
          }
        }
      }
      &:hover{
        .carouseli{
          opacity: 1;
          bottom: 0rem;
          transition: .3s ease;
        }
      }
    }
  }
  .slides {
    img {
      max-width: 100vw;
      width: 100vw;
    }
    .arrow {
      width: 50px;
      margin: 0 2rem;
      position: absolute;
      left: -1rem;
      top: 0%;
      height: 100vh !important;
      cursor: pointer;
      &:hover {
        svg {
          visibility: visible;
        }
      }
      svg {
        visibility: hidden;
        circle {
          fill: var(--dark-gray-v1);
        }
        &:hover {
          circle {
            fill: #434343;
          }
        }
        g,
        path {
          fill: white;
        }
      }
    }
    .rightArrow {
      position: absolute;
      margin-left: 94% !important;
    }
  }
}

@include min(laptop) {
  .container {
    @include flex-align(center, center);
    .content {
      .slides {
        .arrow {
          margin: 0 0.5rem;
          padding: 20px;
        }
      }
      .bottom {
        width: 84%;
        margin-top: 10px;
        svg {
          width: 30px;
          height: 30px;
        }
        .pages {
          h3 {
            font-size: 17px;
          }
        }
      }
    }
  }
  .fullScreenMode{
    .content{
      .bottom {
        width: 100%;
      }
    }
  }
}

@media (max-height: 780px) {
  .container {
    .content {
      margin-top: 1.5rem;
      .slides {
        .arrow {
          width: 32px;
        }
      }
    }
  }
}

@media (max-height: 580px) {
  .container {
    .content {
      img {
        max-width: 40rem;
      }
      .bottom {
        width: 77%;
        margin-top: -2.5rem;
        svg {
          width: 24px;
          height: 24px;
        }
        .pages {
          h3 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

body:global(.universalmenu-closed.rightmenu-closed) {
  :local(.container) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    .content {
      img {
        max-width: 77rem;
      }
    }
  }
}
body:global(.rightmenu-closed) {
  :local(.container) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 3rem;
    .content {
      img {
        max-width: 77rem;
      }
    }
  }
}
body:global(.universalmenu-closed) {
  :local(.container) {
    padding-left: 0;
    padding-right: 0;
    margin-right: 3rem;
    .content {
      img {
        max-width: 77rem;
      }
    }
  }
}
@include custom-max(1024) {
  .container {
    padding-right: 0;
    .content {
      .bottom {
        width: 78%;
        margin-top: 1rem;
      }
    }
  }
  body:global(.universalmenu-closed) {
    :local(.container) {
      margin-right: 0;
      .content {
        .bottom {
          width: 82%;
        }
      }
    }
  }
}
@include max(tablet) {
  .container {
    .content {
      .bottom {
        width: 84%;
        margin-top: 1rem;
      }
    }
  }
  body:global(.universalmenu-closed) {
    :local(.container) {
      .content {
        .bottom {
          width: 88%;
        }
      }
    }
  }
}
