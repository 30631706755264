.searchBox {
    position: relative;
    width: 100%;

    .searchInput {
        width: -webkit-fill-available;
        background-color: transparent;
        border: 1px solid #80808047;
        color: #9F9F9F;
        padding: 10px 10px 10px 30px;
        outline: none;
        border-radius: 20px;
        &.showButton{
            padding: 10px 30px;
        }
        ::placeholder {
            color: #9F9F9F;
        }
    }

    .searchButton {
        position: absolute;
        left: 5px;
        top: 55%;
        transform: translateY(-50%);
        background: none;
        border: none;
        color: #777;
        cursor: pointer;

        .magnifierIcon {
            fill: #777;
            width: 13px!important;
            height: 20px!important;
        }
    }
}


.clearButton {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #777;
    cursor: pointer;
    font-size: 18px;
    line-height: 1;
    padding: 0;
    margin: 0;
    svg{
        width: auto !important;
        height: auto !important;
        zoom: .8;
        &:hover{
            cursor: pointer;
            path, g{
                stroke: rgba(255, 255, 255, 0.804);
            }
        }
    }
  }
  