
@import "../../../../assets/scss/_media-queries.scss";

.gridContainer {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
}

@include max(tablet) {

  body:global(.universalmenu-closed) {
    :local(.gridContainer) {
      padding-left: 0;
    }
  }
  body:global(.rightmenu-closed) {
    :local(.gridContainer) {
      padding-right: 0;
    }
  }
  body:global(.universalmenu-closed.rightmenu-closed){
    :local(.gridContainer){
      padding-right: 0;
      padding-left: 0;
    }
  }

}

