.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 83px;
    padding-left: 19px;
    height: 70px;

    background: #242424;
}

.hr {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid #E6E8F0;
    margin-bottom: 21px;
}

.navigationButton {
    width: 173.29px;
    height: 38px;

    background: #EC7600;
    border-radius: 4px;

    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    text-align: center;
    letter-spacing: -0.23px;
    text-transform: capitalize;
}

.meta {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 20px;
    color: #9b9b9b;
}

.previousButton {
    margin-right: 25px;
}

.cursorDisallowed {
    cursor: not-allowed;
    background: rgba(61, 61, 61, 0.5);
    border: 1px solid rgba(136, 139, 140, 0.12);
    color: #8f8c8c;
}


@media(max-width: 1024px){
    .container {
        gap: 28px;
        height: 150px;
    }

    .navigationButton {
        width: 100.29px;
        height: 25px;
        margin-bottom: 5px;
    }
}
  
